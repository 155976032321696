// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { DGF1JP2023UserRedirect, DGF1JP2023FormResponse, DGF1JP2023ChampionshipQuest, DGF1JP2023Waitlist } = initSchema(schema);

export {
  DGF1JP2023UserRedirect,
  DGF1JP2023FormResponse,
  DGF1JP2023ChampionshipQuest,
  DGF1JP2023Waitlist
};