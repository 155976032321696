import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Box, Card, Button, Grid, Typography, Stack, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation, Trans } from "react-i18next";
import { MainContext } from "../../contexts/MainContext";
//import imgNFT from "../../assets/images/honda-suzuka-circuit.png";
//import { callProxyAsset } from "../../functions/callProxyAsset";
import { callProxyEventInfo } from "../../functions/callProxyEventInfo";

const ClaimPopup = ({handleClose}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const mainContext = React.useContext(MainContext);
  const [claimImgUrl, setClaimImgUrl] = React.useState("");
  const [claimNftName, setClaimNftName] = React.useState("");
  //const [claimWhitelistContract, setClaimWhitelistContract] = React.useState("");
  const [showClaimProgress, setShowClaimProgress] = React.useState(false);
  //const [showClaimCompleted, setShowClaimCompleted] = React.useState(false);
  const [showAlreadyClaimed, setShowAlreadyClaimed] = React.useState(false);

  const closeBtnClick = () => {
    mainContext.updateCompleteClaimEvent();
    handleClose();
  };

  const claimScannedNFT = async () => {
    mainContext.claimScannedNFT();

    // Show Progress
    setShowClaimProgress(true);

    // Polling to check wallet asset to see any balance on whitelisted collection
    setPollCounter(0);
  };

  const [ pollCounter, setPollCounter] = React.useState(100);
  const pollCounterValid = pollCounter < 10;

  React.useEffect(() => {
    if (mainContext.redirectClaimEventId !== "") {
      // Query event info for display
      callProxyEventInfo({"event_id": mainContext.redirectClaimEventId}, (event_data) => {
        //console.log("[ClaimPopup] event_data: ", event_data);
        if (event_data !== undefined) {
          const data = JSON.parse(event_data);
          setClaimNftName(data?.event_info?.event_title);
          setClaimImgUrl(data?.event_info?.event_img_url);
          //setClaimWhitelistContract(data?.event_info?.contract_details?.contract_address)

          //console.log("[ClaimPopup] image: ", data?.event_info?.event_img_url);
          //console.log("[ClaimPopup] name: ", data?.event_info?.event_title);
          if (data?.claim_info?.valid_to_claim === true) {
            setShowAlreadyClaimed(false);
          } else {
            setShowAlreadyClaimed(true);
          }
        }

        // Dev
        //setPollCounter(0);
      }, (event_err) => {
        console.log("[ClaimPopup] Error in callProxyEventInfo: ", event_err);
      });
    }
  }, [mainContext.redirectClaimEventId]);
  
  React.useEffect(() => {
    const intervalId = pollCounterValid && setInterval(() => {
      setPollCounter((t) => t + 1);
    }
    //, 20000);
    , 1000);
    return () => clearInterval(intervalId)
  }, [pollCounterValid]);

  React.useEffect(() => {
    if (pollCounterValid) {
      // Skip wallet check and just notify user
      //checkWalletAsset(mainContext.walletAddress);
      //console.log("checking counter: ", pollCounter);
      if (pollCounter >= 8) {
        // Close the popup form
        closeBtnClick();
      }
    }
  }, [pollCounter]);

  // const checkWalletAsset = () => {
  //   if (mainContext.walletAddress !== "") {
  //     // Call Proxy Asset API
  //     callProxyAsset({"wallet_address":  mainContext.walletAddress}, (asset_data) => {
  //     //callProxyAsset({"wallet_address": "0x03419c927649bfc1d9962637febf6c50ffa787e4"}, (asset_data) => {
  //       if ((asset_data !== undefined) && (asset_data.length > 0)) {
  //         // Check any balance on whitelisted collection
  //         //console.log("[checkWalletAsset] asset_data: ", asset_data);

  //         const whitelisted = claimWhitelistContract;
  //         const asset = JSON.parse(asset_data);
  //         whitelisted.every(element => {
  //           const anyAsset = asset.find((item) => item["contract_address"].toLowerCase() === element.toLowerCase());
  //           if (anyAsset !== undefined) {
  //             mainContext.onClaimScannedCompleted();
  //             //setShowClaimCompleted(true);

  //             setTimeout(() => {
  //               // Close the popup form
  //               handleClose();  
  //             }, 3000);
  //             return false;
  //           }
  //         });

  //         mainContext.setIsWalletAssetLoaded(true);
  //         mainContext.setWalletAsset({ nft: JSON.parse(asset_data)});

          
  //       }
  //     }, (asset_err) => {
  //       mainContext.log_event_analytics("error_proxy_asset", { "wallet_address": mainContext.walletAddress, "error": asset_err });
  //       console.log("[checkWalletAsset] Error in callProxyAsset: ", asset_err);
  //     });
  //   }
  // };
  
  const popupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "350px",
    height: "600px",
    flexShrink: "0",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "2px",    
  };

  const buttonXStyle = {
    position: "absolute",
    top: "5px",
    right: "-2px",
    color: "#2B2B2B",
  };

  const progressRedStyle = {
    "&.MuiCircularProgress-colorPrimary": {
      color: "rgba(255, 0, 0, 0.84) !important",
    }
  };

  const progressStyle = {
    "&.MuiCircularProgress-colorPrimary": {
      color: "rgba(255, 255, 255, 0.84) !important",
    }
  };

  return (
    <>
      <Card 
        sx={popupStyle}
      >
        <Button variant="text" onClick={() => closeBtnClick()} sx={buttonXStyle}>
          <CloseIcon />
        </Button>
        <Box mx={2} mt={3}>
          { claimImgUrl === "" ? (
            <Grid container>
              <Grid item mt={30} xs={12} container justifyContent={"center"}>
                <CircularProgress sx={progressRedStyle} />
              </Grid>
            </Grid>
          ) : (
            <Fragment>
              <Grid container alignItems={"center"}>
                <Grid item mt={1} xs={12} container justifyContent={"center"}>
                  <img src={claimImgUrl} width={308} alt={claimNftName} />
                </Grid>
                <Grid item mt={1} xs={12} container justifyContent={"center"}>
                  <Typography variant="dg_h1">
                    {claimNftName}
                  </Typography>
                </Grid>
                {
                  showAlreadyClaimed ? (
                    <Grid item mt={1} xs={12} container justifyContent={"center"}>
                      <Typography variant="dg_body">
                        {t("claim_popup.already_claimed")}
                      </Typography>
                    </Grid>
                  ) : (
                  <Fragment>
                    { !showClaimProgress ? (
                    <Grid item xs={12} mt={2} container justifyContent={"center"}>
                      <Box 
                        sx={{
                          display: "flex",
                          width: "310px",
                          height: "48px",                
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          flexShrink: "0",
                          borderRadius: "8px",
                          border: "1px solid var(--primary-red, #C00)",
                        }}>
                        <Button variant="cta" fullWidth onClick={() => claimScannedNFT()} disabled={showClaimProgress}>
                        {showClaimProgress ? (
                          <Stack direction={"row"} spacing={0.8} alignItems={"center"}>
                            <Typography variant="dg_body" sx={{ fontWeight: "600", color: "var(--primary-white, #FFF);" }}>
                              {t("claim_popup.progress")}
                            </Typography>
                            <CircularProgress sx={progressStyle} />
                          </Stack>
                        ) : (
                          <Typography variant="dg_body" sx={{ fontWeight: "600", color: "var(--primary-white, #FFF);" }}>
                            {t("claim_popup.button")}
                          </Typography>
                        )}
                        </Button>
                      </Box>
                    </Grid>
                    ) : (
                    <Grid item xs={12} mt={2} container justifyContent={"center"}>
                      <Typography variant="dg_body">
                        <Trans i18nKey={"claim_popup.progress_message"} 
                          components={{
                            br: <br />,
                            highlight: <span style={{ fontWeight: "600" }} />,
                          }}
                          values={{ counter: (8 - pollCounter) }} />
                        {/* {`${t("claim_popup.progress_message")} (${8 - pollCounter })`} */}
                      </Typography>
                    </Grid>
                    )}
                    {/* { showClaimCompleted && (
                      <Grid item mt={1} xs={12} container justifyContent={"center"}>
                        <Typography variant="dg_body2">
                          {t("claim_popup.completed")}
                        </Typography>            
                      </Grid>
                    )} */}
                  </Fragment>
                )}
              </Grid>
              </Fragment>
          )}
        </Box>
      </Card>
    </>
  );
};

ClaimPopup.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ClaimPopup;
