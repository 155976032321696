import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
I18n.putVocabularies(translations);
I18n.setLanguage('ja');

I18n.putVocabularies({
  en: {
    'Sign In': 'Sign In',
    'Sign Up': 'Sign Up',
    "signin.label_email": "Email",
    "signin.placeholder_email": "Enter your email",
    "signup.label_password": "Password",
    "signup.placeholder_password": "Enter your password",
    "signup.label_confirm_password": "Confirm Password",
    "signup.label_tc_acknowledgement": "I have read and agreed to the Gryfyn Terms and Conditions and Privacy Policy.",
    "forcenewpassword.placeholder_password": "Please input a new password",
    "resetpassword.placeholder_username": "Email",
  },
  ja: {
    'Sign In': 'サインイン',
    'Sign Up': "サインアップ",
    "signin.label_email": "電子メイル",
    "signin.placeholder_email": "メールアドレスを入力",
    "signup.label_password": "Password",
    "signup.placeholder_password": "Enter your password",
    "signup.label_confirm_password": "Confirm Password",
    "signup.label_tc_acknowledgement": "I have read and agreed to the Gryfyn Terms and Conditions and Privacy Policy.",
    "forcenewpassword.placeholder_password": "Please input a new password",
    "resetpassword.placeholder_username": "Email",
  },
  
});