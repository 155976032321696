import { Box, Stack, Typography } from "@mui/material";
import { formatWalletAddress } from "../../assets/utilities/formatWalletAddress";
import CopyToClipboardButton from "../../components/CopyToClipboardButton";

// eslint-disable-next-line react/prop-types
const DisplayWallet = ({walletAddress}) => {
  return (
    <Box
      sx={{
        borderRadius: "30px",
        background: "rgba(43, 43, 43, 0.06)",
      }}
    >
      <Stack direction="row" mx={0.75} ml={1.25} my={0.75} spacing={1} alignItems="center">
        <Typography variant="dg_body2">
          {formatWalletAddress(walletAddress)}
        </Typography>
        <CopyToClipboardButton data={walletAddress} />
      </Stack>
    </Box>
  );
};

export default DisplayWallet;
