/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { Box, Typography, Backdrop, CircularProgress, Button, Grid, Card, Stack, Modal } from "@mui/material";
//import { button_cta } from "../../assets/themes/button";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useAuth, CustAuthState } from "../../contexts/CustAuthContext";
import { MainContext } from "../../contexts/MainContext";
import { useTranslation, Trans } from "react-i18next";
import Error from "../Error";
import HondaKey from "../../assets/images/nft-honda-key.png";
import Footer from "../../components/Footer";
import NotAuthorized from "../NotAuthorized";
import CreatedWallet from "../CreatedWallet";
// Custom Styles
import { highlight as highlightStyle } from "../../assets/styles/highlight";
import DisplayWallet from "../../components/DisplayWallet";
import { trackableBrowseNewTab } from "../../components/TrackableExtLink/TrackableAction";

// Welcome page is the default authenticated page of showing Wallet Address
function Welcome() {
  const { authState } = useAuth();
  const navigate = useNavigate();
  const [showPage, setShowPage] = React.useState(false);
  const mainContext = React.useContext(MainContext);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openNewWallet, setOpenNewWallet] = React.useState(false);
  const [afterLoaded, setAfterLoaded] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  //const handleNewWalletClose = () => {
  const handleNewWalletClose = React.useCallback(() => {
    setOpenNewWallet(false);
  });
  // React.useEffect(() => {
  //   handleOpen();
  // }, []);

  const claimHondaKey = async () => {
    //console.log("[claimHondaKey] ");
    mainContext.claimHondaKeyNFT();
    
    // Navigate to profile page
    navigate("/profile");
  };

  React.useEffect(() => {
    if (authState === CustAuthState.SignedIn) {
      setShowPage(true);
    } else {
      setShowPage(false);
    }
  }, [authState]);

  React.useEffect(() => {
    if (mainContext.loadingWallet) {
      handleOpen();
    } else {
      handleClose();
      if (mainContext.userEmail) {
        setAfterLoaded(true);
      }
    }
  }, [mainContext.loadingWallet]);

  React.useEffect(() => {
    if (mainContext.userEmail) {
      if (mainContext.isNewWallet) {
        setOpenNewWallet(true);
      }
    }
  }, [mainContext.isNewWallet]);

  return (
    <>
      <Box>
        {showPage ? (
          <Fragment>
            <Grid container px={0} py={0} mx={0} my={0}>
              <Grid item>
                <Fragment>
                  {afterLoaded && (
                    <Box>
                      <Grid container alignItems={"center"}>
                        <Grid item container xs={12} justifyContent={"center"}>
                          <img src={HondaKey} width={246} alt="Honda" />
                        </Grid>
                        <Grid item container xs={12} justifyContent={"center"}>
                          <Typography variant="dg_h1">
                            <Trans
                              i18nKey="welcome.title"
                              components={{ br: <br />, highlight: <span style={highlightStyle} /> }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item container xs={12} justifyContent={"center"}>
                          <Typography variant="dg_body1">
                            <Trans
                              i18nKey="welcome.subtitle"
                              components={{ br: <br />, highlight: <span style={highlightStyle} /> }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          &nbsp;
                        </Grid>                        
                        <Grid item container xs={12} mt={2} justifyContent={"center"}>
                          <Button variant="cta" fullWidth
                            onClick={claimHondaKey}>
                            <Typography variant="dg_button">
                              {t("welcome.ctabutton")}
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {!afterLoaded && (
                    <Box>
                      {/* <Error /> */}
                      &nbsp;
                    </Box>
                  )}
                </Fragment>
              </Grid>
            </Grid>

            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openNewWallet}
              onClose={handleNewWalletClose}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >

              <CreatedWallet handleClose={handleNewWalletClose} />
            </Modal>
          </Fragment>
        ) : (
          <NotAuthorized />
        )}
      </Box>

      <Box mx={0} mt={4}>
        <Footer />
      </Box>
    </>
  );
}

export default Welcome;
