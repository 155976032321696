import React from "react";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import { useAuth, CustAuthState } from "../../contexts/CustAuthContext";
import NotAuthorized from "../NotAuthorized";
import { MainContext } from "../../contexts/MainContext";
import { useNavigate } from "react-router-dom";

// Home page is the default landing page to be routed to other
function Home() {
  const { authState } = useAuth();
  const [showPage, setShowPage] = React.useState(false);
  const navigate = useNavigate();
  const mainContext = React.useContext(MainContext);
  const [openLoading, setOpenLoading] = React.useState(false);

  React.useEffect(() => {
    // Route to page
    if (mainContext.homeRouteTo) {
      navigate(mainContext.homeRouteTo);
    }
  }, [mainContext.homeRouteTo]);

  React.useEffect(() => {
    if (mainContext.loadingWallet) {
      setOpenLoading(true);
    } else {
      setOpenLoading(false);      
    }
  }, [mainContext.loadingWallet]);

  React.useEffect(() => {
    if (authState === CustAuthState.SignedIn) {
      setShowPage(true);
    } else {
      setShowPage(false);
    }
  }, [authState]);  
  return (
    <>
      <Box>
        {!showPage && (
          <NotAuthorized />
        )}
      </Box>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Home;
