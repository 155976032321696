import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router";
import { Grid, Typography, Button } from "@mui/material";
import imgError from "../../assets/images/error.png";

function Error() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} lg={12} mt={4} container justifyContent={"center"}>
          <img src={imgError} alt="error" width={"300px"} />
        </Grid>
        <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
          <Typography variant="dg_h1">
            {t("error.general_title")}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
          <Typography variant="dg_body1">
            <Trans i18nKey="error.general_details"
              components={{ support: <a rel="noopener noreferrer" href="mailto:support@gryfyn.io" /> }} />
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
          <Button variant="cta" fullWidth onClick={() => {
            navigate("/");
          }}
          >
            <Typography variant="dg_button">
              {t("error.back_to_home")}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default Error;
