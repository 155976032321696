import { Button, Typography, Snackbar } from "@mui/material";

//import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState, useContext } from "react";

import { MainContext } from "../../contexts/MainContext";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/prop-types
const CopyToClipboardButton = ({data}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const mainContext = useContext(MainContext);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(data);
    mainContext.log_event_analytics("copy_to_clipboard", { data: data });
  };

  return (
    <>
      <Button 
        sx={{
          borderRadius: "15px",          
          background: "var(--primary-darkgrey, #2B2B2B)",
          textTransform: "none",
          "&:hover": {
            borderRadius: "15px",            
            background: "var(--primary-darkgrey, #2B2B2B)",
          },
          "&:active": {
            borderRadius: "8px",
            background: "var(--primary-darkgrey, #2B2B2B)",
          }, 
        }}
        onClick={handleClick}
      >
        <Typography variant="dg_body2" sx={{ color: "white", fontSize: "10px" }}>
          {t("welcome.copy")}
        </Typography>
      </Button>      
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message={t("component.copytoclipboardbutton.message")}
      />
    </>
  );
};

export default CopyToClipboardButton;
