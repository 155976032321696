import React from "react";
import { Container, Box, Grid } from "@mui/material";
//import { Auth } from "aws-amplify";
//import { Link } from "@aws-amplify/ui-react";
import logoHonda from "../../assets/images/logo-honda-sm.png";
import logoGryfyn from "../../assets/images/logo-gryfyn-sm.png";

function Navbar() {
  return (
    <Container sx={{ position: "sticky", top: 0, zIndex: 10, background: "white" }}>
      <Box py={1} mx={0}>
        <Grid container alignItems={"center"}>
          <Grid item container xs={6} justifyContent={"flex-start"}>
            <img src={logoHonda} width={158} alt="Honda" />
          </Grid>
          <Grid item container xs={6} justifyContent={"flex-end"}>
            <img src={logoGryfyn} width={118} alt="Gryfyn" />            
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Navbar;
