import React, { Fragment } from "react";
import { Grid, Box, Typography, Divider, Modal } from "@mui/material";
//import { Auth } from "aws-amplify";
import { useAuth, CustAuthState } from "../../contexts/CustAuthContext";
import { MainContext } from "../../contexts/MainContext";
import { useTranslation } from "react-i18next";
import Avatar from "../../components/Avatar";
import DisplayWallet from "../../components/DisplayWallet";
import NotAuthorized from "../NotAuthorized";
//import ImgPromoHonda from "../../assets/images/promo-honda-key.png";
import AssetView from "./AssetView";
//import BannerView from "./BannerView";
import ClaimPopup from "./ClaimPopup";
import Footer from "../../components/Footer";
//import TrackableExtLink from "../../components/TrackableExtLink";
import SignoutLink from "../../components/SignoutLink";
import { useCookies } from 'react-cookie';

function Profile() {
  const { authState } = useAuth();
  const [ showPage, setShowPage ] = React.useState(false);
  const mainContext = React.useContext(MainContext);
  const { t } = useTranslation();
  const [cookies] = useCookies(['redirect_claim', 'claim_event_id',]);
  const [openClaimPopup, setOpenClaimPopup] = React.useState(false);

  React.useEffect(() => {
    //console.log("Profile page");
    // Retrieve from cookie to see any claim redirect
    const is_redirect_claim = cookies.redirect_claim;
    const claim_event_id = cookies.claim_event_id;
    if (is_redirect_claim !== undefined && is_redirect_claim === true) {
      // Popup Claim Page
      setOpenClaimPopup(true);

      mainContext.setRedirectClaimEventId(claim_event_id);
    }
  }, []);

  const handleClaimPopupClose = React.useCallback(() => {
    setOpenClaimPopup(false);
  });

  React.useEffect(() => {    
    if (authState === CustAuthState.SignedIn) {
      setShowPage(true);
    } else {
      setShowPage(false);
    }
  }, [authState]);

  return (
    <>
      <Box>
        {showPage ? (
          <Fragment>
            <Grid container px={0} py={0} mx={0} my={0}>
              <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
                <Box>
                  <Avatar email={mainContext.userEmail} walletAddress={mainContext.walletAddress} />
                </Box>
              </Grid>
              <Grid item container xs={12} mt={2} justifyContent={"center"}>
                <Typography variant="dg_h3">
                  {t("walletaddress")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12} mt={1} container justifyContent={"center"}>
                <DisplayWallet walletAddress={mainContext.walletAddress} />
              </Grid>
              {/* <BannerView 
                imgDisplay={ImgPromoHonda}
                imgAlt="Honda KEY NFT"
                textKey="promo.honda_f1_title"
                ctaKey="promo.joinnow"
                ctaUrl={mainContext.hondaEventUrl}
                isNewTab={false}
              /> */}
              {/* <BannerView 
                imgDisplay={ImgPromoHonda}
                imgAlt="Honda KEY NFT"
                textKey="promo.honda_f1_onday"
                ctaKey="promo.joinnow"
                ctaUrl="/quiz"
                isNewTab={false}
              /> */}
              {/* <Grid item xs={12} lg={12} mt={3} container justifyContent={"center"}>
                <Box
                  sx={{
                    width: "350px",
                    height: "132px",
                    flexShrink: "0",
                    borderRadius: "10px",
                    border: "1.5px solid rgba(204, 0, 0, 0.15)",
                    background: "rgba(204, 0, 0, 0.08)",
                  }}
                >
                  <Grid container justifyContent={"center"} alignItems={"center"} spacing={5}>
                    <Grid item xs={4} alignItems={"center"}>
                      <Box ml={1} mt={1}>
                        <img src={ImgPromoHonda} width={"112px"} style={{borderRadius: "5px"}} alt="Honda KEY NFT" />
                      </Box>
                    </Grid>
                    <Grid item xs={8} container justifyContent={"left"}>
                      <Stack direction="column" spacing={0.5} alignItems={"flex-start"}>
                        <Typography variant="dg_body1" sx={{ fontWeight: "400" }} textAlign={"left"}>
                          <Trans i18nKey="promo.honda_f1_title" components={{ br: <br />, b: <span style={{ fontWeight: "700" }} /> }} />
                        </Typography>
                        <Typography />
                        <Typography />
                        <Typography />
                        <Button variant="text" sx={{textTransform: "none", padding: "0"}}>
                          <Typography variant="dg_link" sx={{ color: "var(--primary-red, #C00)" }}
                            component={TrackableExtLink}
                            // to={mainContext.hondaEventUrl}
                            to="/quiz"
                            //target="_blank"
                            >
                            {t("promo.joinnow")}
                          </Typography>
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>               */}
            </Grid>

            <Box>&nbsp;</Box>
            <Divider variant="middle" />

            <Grid container>
              <AssetView />              
            </Grid>

            <Grid container>
              <Grid item container xs={12} mt={2} justifyContent={"center"}>
                {/* <Button variant="text" onClick={() => Auth.signOut()}>
                  <Typography variant="dg_link">
                    {t("signout")}
                  </Typography>
                </Button> */}
                <SignoutLink />
              </Grid>
            </Grid>
          </Fragment>
        ) : (
          <Grid item xs={12} lg={12} container justifyContent={"center"}>
            <NotAuthorized />
          </Grid>
        )}        
      </Box >

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openClaimPopup}
        onClose={handleClaimPopupClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >

        <ClaimPopup handleClose={handleClaimPopupClose} />
      </Modal>
      <Box mx={0} mt={4}>
        <Footer />
      </Box>        
    </>
  )
}

export default Profile;
