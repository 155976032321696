/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const proxyeventinfo = /* GraphQL */ `
  query Proxyeventinfo($input: AWSJSON) {
    proxyeventinfo(input: $input)
  }
`;
export const getDGF1JP2023UserRedirect = /* GraphQL */ `
  query GetDGF1JP2023UserRedirect($pk: String!) {
    getDGF1JP2023UserRedirect(pk: $pk) {
      pk
      userEmail
      redirectUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDGF1JP2023UserRedirects = /* GraphQL */ `
  query ListDGF1JP2023UserRedirects(
    $pk: String
    $filter: ModelDGF1JP2023UserRedirectFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDGF1JP2023UserRedirects(
      pk: $pk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        userEmail
        redirectUrl
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDGF1JP2023UserRedirects = /* GraphQL */ `
  query SyncDGF1JP2023UserRedirects(
    $filter: ModelDGF1JP2023UserRedirectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDGF1JP2023UserRedirects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        pk
        userEmail
        redirectUrl
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDGF1JP2023FormResponse = /* GraphQL */ `
  query GetDGF1JP2023FormResponse($id: ID!) {
    getDGF1JP2023FormResponse(id: $id) {
      id
      owner
      email
      formId
      walletAddress
      responseId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDGF1JP2023FormResponses = /* GraphQL */ `
  query ListDGF1JP2023FormResponses(
    $filter: ModelDGF1JP2023FormResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDGF1JP2023FormResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        email
        formId
        walletAddress
        responseId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDGF1JP2023FormResponses = /* GraphQL */ `
  query SyncDGF1JP2023FormResponses(
    $filter: ModelDGF1JP2023FormResponseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDGF1JP2023FormResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        email
        formId
        walletAddress
        responseId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDGF1JP2023ChampionshipQuest = /* GraphQL */ `
  query GetDGF1JP2023ChampionshipQuest($id: ID!) {
    getDGF1JP2023ChampionshipQuest(id: $id) {
      id
      owner
      email
      gameEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDGF1JP2023ChampionshipQuests = /* GraphQL */ `
  query ListDGF1JP2023ChampionshipQuests(
    $filter: ModelDGF1JP2023ChampionshipQuestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDGF1JP2023ChampionshipQuests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        email
        gameEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDGF1JP2023ChampionshipQuests = /* GraphQL */ `
  query SyncDGF1JP2023ChampionshipQuests(
    $filter: ModelDGF1JP2023ChampionshipQuestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDGF1JP2023ChampionshipQuests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        email
        gameEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDGF1JP2023Waitlist = /* GraphQL */ `
  query GetDGF1JP2023Waitlist($email: String!) {
    getDGF1JP2023Waitlist(email: $email) {
      email
      requested_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDGF1JP2023Waitlists = /* GraphQL */ `
  query ListDGF1JP2023Waitlists(
    $email: String
    $filter: ModelDGF1JP2023WaitlistFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDGF1JP2023Waitlists(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        requested_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDGF1JP2023Waitlists = /* GraphQL */ `
  query SyncDGF1JP2023Waitlists(
    $filter: ModelDGF1JP2023WaitlistFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDGF1JP2023Waitlists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        email
        requested_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
