const button_cta = {
  color: "var(--primary-white, #FFFFFF)",
  borderRadius: "8px",
  background: "var(--primary-red, #C00)",
  textTransform: "none",
  maxWidth: "350px",
  height: "48px",
  flexShrink: "0",
  "&:hover": {
    background: "var(--primary-red, #C00)",
  },
  "&.Mui-active": {
    background: "var(--primary-red, #C00)",
  },
  "&.Mui-disabled": {
    background: "#CC000059",
  },
};

const button_try_other = {
  color: "#2B2B2B",
  borderRadius: "8px",
  border: "1px solid rgba(43, 43, 43, 0.20)",
  background: "var(--primary-white, #FFF)",
  textTransform: "none",
  maxWidth: "350px",
  height: "48px",
  flexShrink: "0",
  "&:hover": {
    background: "var(--primary-white, #FFF)",
  },
  "&.Mui-active": {
    background: "var(--primary-white, #FFF)",
  },
  "&.Mui-disabled": {
    background: "rgba(43, 43, 43, 0.20)",
  },
};

const button_link = {
  color: "var(--primary-grey, #676767)",
  textDecoration: "underline",
  border: "none",
  background: "transparent",
  textTransform: "none",
  maxWidth: "350px",
  flexShrink: "0",
  "&:hover": {
    background: "transparent",
    textDecoration: "underline",
  },
  "&.Mui-active": {
    background: "transparent",
    textDecoration: "underline",
  },
  "&.Mui-disabled": {
    color: "var(--primary-lightgrey, #D8D8D8)",
    background: "transparent",
    textDecoration: "underline",
  },
};

const button_lng_link = {
  color: "var(--primary-grey, #676767)",
  textDecoration: "none",
  border: "none",
  background: "transparent",
  textTransform: "none",
  maxWidth: "350px",
  flexShrink: "0",
  "&:hover": {
    background: "transparent",
  },
  "&.Mui-active": {
    background: "transparent",
  },
  "&.Mui-disabled": {
    color: "var(--primary-lightgrey, #D8D8D8)",
    background: "transparent",
  },
};

const button_social = {  
  color: "var(--primary-red, #C00)",
  borderRadius: "8px",
  border: "1px solid var(--primary-red, #C00)",
  background: "var(--primary-white, #FFF)",
  textTransform: "none",
  maxWidth: "350px",
  height: "48px",
  flexShrink: "0",

  "&:hover": {
    borderRadius: "8px",
    border: "1px solid var(--primary-red, #C00)",
    background: "var(--primary-red, #FFF)",
  },
  "&:active": {
    borderRadius: "8px",
    border: "1px solid var(--primary-red, #C00)",
    background: "var(--primary-red, #FFF)",
  },  
  "&.Mui-disabled": {
    background: "#CC000059",
  },
};

const button_cookie_accept = {
  color: "#2B2B2B",
  borderRadius: "15px",
  background: "var(--primary-darkgrey, #2B2B2B)",
  textTransform: "none",
  maxWidth: "128px",
  height: "28px",
  flexShrink: "0",
  "&:hover": {
    background: "var(--primary-darkgrey, #2B2B2B)",
  },
  "&.Mui-active": {
    background: "var(--primary-darkgrey, #2B2B2B)",
  },
  "&.Mui-disabled": {
    background: "rgba(43, 43, 43, 0.20)",
  },
};

export { button_cta, button_try_other, button_link, button_lng_link, button_social, button_cookie_accept };
