import React from "react";
import { theme } from "../../assets/themes";
import { Container, Box, Grid, Typography, ThemeProvider } from "@mui/material";
//import { Auth } from "aws-amplify";
//import { Link } from "@aws-amplify/ui-react";
import HondaKey from "../../assets/images/nft-honda-key.png";
import { useTranslation, Trans } from "react-i18next";


function LandingSignUp() {  
  // eslint-disable-next-line no-unused-vars
  const {t} = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Box py={1} mx={0}>
          <Grid container alignItems={"center"}>
            <Grid item container xs={12} justifyContent={"center"}>
              <img src={HondaKey} width={246} alt="Honda" />
            </Grid>
            {/* <Grid item container xs={12} justifyContent={"center"}>
              <Typography variant="dg_h1">
                {t("signin.title")}                
              </Typography>
            </Grid> */}
            <Grid item container xs={12} mt={1.5} justifyContent={"center"}>
              <Typography variant="dg_h1">
                <Trans 
                  i18nKey="signin.title" 
                  components={{ br: <br />}} 
                />
              </Typography>
            </Grid>
            <Grid item container xs={12} mt={1} justifyContent={"center"}>
              <Typography variant="dg_body1">
                <Trans 
                  i18nKey="signin.subtitle" 
                  components={{ br: <br />}} 
                />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default LandingSignUp;
