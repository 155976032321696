const typography_dg_h1 = {
  color: "var(--primary-darkgrey, #2B2B2B)",
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Open Sans",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
};

const typography_dg_h3 = {
  color: "var(--primary-darkgrey, #2B2B2B)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Open Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "normal",
};

const typography_body1 = {
  color: "var(--primary-darkgrey, #2B2B2B)",
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
};

const typography_body2 = {
  color: "var(--primary-grey, #676767)",
  fontFamily: "Open Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
};

const typography_button = {  
  color: "#FFF",
  textAlign: "center",
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "100%",
};

const typography_link = {  
  color: "var(--primary-grey, #676767)",
  textAlign: "center",
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "100%",
  textDecorationLine: "underline",
};

const typography_footer = {
  color: "var(--primary-darkgrey, #2B2B2B)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Noto Sans TC",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
}

export { typography_dg_h1, typography_dg_h3, typography_body1, typography_body2, typography_button, typography_link, typography_footer }
