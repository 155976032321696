import React, { Fragment } from 'react';
import { useCountdown } from './useCountdown';
import { Button, Typography, Stack } from '@mui/material';

// eslint-disable-next-line react/prop-types
const CountdownButton = ({ targetDate, label, onClick }) => {
  // eslint-disable-next-line no-unused-vars
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  const [totalSeconds, setTotalSeconds] = React.useState(0);

  // React.useEffect(() => {
  //   console.log("CountdownButton: targetDate: ", targetDate);
  //   console.log("seconds: ", seconds);
  // }, []);

  React.useEffect(() => {
    setTotalSeconds(minutes * 60 + seconds);
  }, [minutes, seconds]);
  
  return (      

    <Button variant="cta" 
      fullWidth
      onClick={onClick} 
      disabled={(totalSeconds > 0)}>
      <Stack direction="row" spacing={1}>
        <Typography variant="dg_button">
          {label}    
        </Typography>
        {totalSeconds > 0 && (
          <Fragment>
            <Typography variant="dg_button">
              {"("}
            </Typography>
            <Typography variant="dg_button">
              {totalSeconds}
            </Typography>
            <Typography variant="dg_button">
              {"s)"}
            </Typography>
          </Fragment>
        )}
      </Stack>
    </Button>
  );
  
};

export default CountdownButton;
