import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router";
import { Grid, Typography, Button } from "@mui/material";

function VerifyFailed() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} lg={12} mt={28} container justifyContent={"center"}>
          <Typography variant="dg_h1">
            {t("verify.failed_warning")}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
          <Typography variant="dg_body1">
            <Trans i18nKey="verify.failed_warning_details" 
              components={{ support: <a rel="noopener noreferrer" href="mailto:support@gryfyn.io" /> }} />
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
          <Button variant="cta" fullWidth onClick={() => {
            navigate("/");
          }}
          >
            <Typography variant="dg_button">
              {t("verify.back_to_home")}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default VerifyFailed;
