import { API } from "aws-amplify";
import { updatewaitlist } from "../graphql/mutations";

export const callUpdateWaitlist = async (data, success, err) => {
  try {
    //console.log("[callUpdateWaitlist] data: ", data);
    const apiRequest = await API.graphql({
      query: updatewaitlist,
      variables: { input: JSON.stringify(data) },
    });
    //console.log("[callUpdateWaitlist] apiRequest: ", apiRequest);
    const response = JSON.parse(apiRequest.data.updatewaitlist);
    
    /*
    Response body documentation
    */
    if (response.statusCode === 200) {
      const response_body = response.body;
      if (response_body) {
        success(response_body);
      } else {
        err(response_body);
      }
    } else {
      err(response.body);
    }
  } catch (error) {
    if (err) {
      err(400, error.toString());
    }
  }
};

export default callUpdateWaitlist;
