/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { Grid, Typography, Box, Stack } from "@mui/material";
import { MainContext } from "../../contexts/MainContext";
import { useTranslation, Trans } from "react-i18next";
import AirdropView from "./AirdropView";

const AssetView = () => {
  const mainContext = React.useContext(MainContext);
  const { t } = useTranslation();
  const [noOfItems, setNoOfItems] = React.useState(0);
  const [ showAirdrop, setShowAirdrop ] = React.useState(false);

  React.useEffect(() => {    
    if (mainContext.isWalletAssetLoaded) {
      // Only check to show Airdrop view if wallet asset is loaded
      if (mainContext.walletAsset.nft) {
        setNoOfItems(mainContext.walletAsset.nft.length);
        if (mainContext.walletAsset.nft.length == 0) {
          setShowAirdrop(true);
        } else {
          // Check aganist the holding
          let toShow = true;
          const whitelisted = mainContext.contractAddressLookup.honda;
          const asset = mainContext.walletAsset.nft;
          whitelisted.every(element => {
            const anyAsset = asset.find((item) => item["contract_address"].toLowerCase() === element.toLowerCase());
            if (anyAsset !== undefined) {
              toShow = false;
              return false;
            }
          });
          setShowAirdrop(toShow);
          
        }
      } else {
        setNoOfItems(0);
        setShowAirdrop(true);
      }
    }
  }, [mainContext.walletAsset]);

  

  React.useEffect(() => {
    if (mainContext.claimState === "submitted") {
      setShowAirdrop(true);
    }
  }, [mainContext.claimState]);

  return (
    <>      
      <Grid item container xs={12} ml={1} mt={2} justifyContent={"center"} alignContent={"center"}>
        <Grid item container xs={9} justifyContent={"flex-start"}>
          <Typography variant="dg_h1">
              {t("profile.asset_title")}
            </Typography>
        </Grid>
        <Grid item container xs={2} mr={1} justifyContent={"flex-end"} alignContent={"center"}>
          <Typography variant="dg_body2" textAlign={"right"}>
            <Trans i18nKey="profile.asset_item_count" values={{ count: noOfItems }} />
          </Typography>
        </Grid>                
      </Grid>

      {/* Update 2023-11-24: Stop all NFT airdrop */}
      {/* {showAirdrop && (
        <Grid item container xs={12} ml={1} mt={2} justifyContent={"center"} alignContent={"center"}>
          <AirdropView />
        </Grid>
      )} */}

      <Grid item container xs={12} ml={1} mr={1} mt={2} justifyContent={"center"} alignContent={"center"}>
        <Stack direction="column" spacing={2}>
          {
            (mainContext.walletAsset != null && mainContext.walletAsset.nft != null && Array.isArray(mainContext.walletAsset.nft)) &&
              mainContext.walletAsset.nft.map((asset, index) => {
              return (
                <Fragment key={`nft-asset-${index}`}>
                  <Box mx={1} my={1} sx={{
                    borderRadius: "20px",
                    background: "#FFF",
                    boxShadow: "0px 2px 14px 0px rgba(0, 0, 0, 0.10)",
                    width: "auto",
                    height: "auto",
                  }}
                  >
                    <Grid container mx={2} my={2} justifyContent={"flex-start"}>
                      {/* <Box item xs={12} mt={1} container justifyContent={"flex-start"}> */}
                      <Grid item container justifyContent={"lex-start"}>
                        
                        <img src={(asset.media?.raw.includes("https://dot.dttd.group")) ? asset.media?.raw : (asset.media?.thumbnail || asset.media?.raw)} alt={asset.title} style={{
                          borderRadius: "10px",
                          //boxShadow: "0px 0px 0px 0px rgba(255, 255, 255, 0.60)",
                          //backgroundSize: "cover",
                          width: "91%",
                        }} />
                        
                      </Grid>
                      <Grid item xs={8} ml={0.1} mt={1} container justifyContent={"flex-start"}>
                        <Stack direction="column" spacing={1}>
                          <Typography variant="dg_h3">
                            {asset.title || ""}
                          </Typography>
                          <Typography variant="dg_body2">
                            <Trans i18nKey={"profile.asset_quantity"} values={{ qty: asset.balance || 0 }} />
                          </Typography>
                        </Stack>

                      </Grid>
                      <Grid item xs={4} mt={1} container justifyContent={"center"}>
                        {/* Placeholder for button of Show QR code */}
                        <></>
                      </Grid>
                    </Grid>
                  </Box>
                </Fragment>
              );
            })
          }
        </Stack>
      </Grid>

    </>
  )
};

export default AssetView;

