/* eslint-disable react/prop-types */
import * as React from "react";
import { Auth } from "aws-amplify";
import { callSignIn } from "../functions/callSignIn";

export const CustAuthState = {
  Unknown: "Unknown", // Initial state on page load
  Unauthenticated: "Unauthenticated",
  SentMagicLink: "SentMagicLink",
  ResentMagicLink: "ResentMagicLink",
  SignedIn: "SignedIn",
};

export const CustAuthContext = React.createContext({
  loggedIn: null,
  setLogout: () => {},
  isAuthenticated: () => Promise.resolve(false),
  signIn: () => Promise.resolve(null),
  answerCustomChallenge: () => Promise.resolve(true),
  signOut: () => Promise.resolve(),
  authState: CustAuthState.Unknown,
  setAuthState: () => {},
  lastMagicLinkSentAt: null,
  setLastMagicLinkSentAt: () => {},
  lastSubmittedEmail: null,
  nextMagicLinkAllowedAt: 0,
  resetMagicLinkFlow: () => {},
});




const CustAuthProvider = (props) => {    
  const [loggedIn, setLoggedIn] = React.useState(null); // default to null  
  const [authState, setAuthState] = React.useState(CustAuthState.Unknown);
  const [lastMagicLinkSentAt, setLastMagicLinkSentAt] = React.useState(null);
  const [lastSubmittedEmail, setLastSubmittedEmail] = React.useState(null);
  const [nextMagicLinkAllowedAt, setNextMagicLinkAllowedAt] = React.useState(0);

  const isAuthenticated = React.useCallback(async () => {
    try {
      await Auth.currentSession();
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  React.useEffect(() => {
    isAuthenticated().then((res) => {
      setLoggedIn(res);
      if (res) {
        setAuthState(CustAuthState.SignedIn);
      }
    })
  }, [isAuthenticated]);

  const setLogout = () => {
    setLoggedIn(false);
    setAuthState(CustAuthState.Unauthenticated);
  };

  const resetMagicLinkFlow = () => {
    setAuthState(CustAuthState.Unauthenticated);
    // Not to reset sent time
    //setLastMagicLinkSentAt(null);
  };

  // Custom Auth Flow: Sign In -> trigger to send Magic Link
  const signIn = React.useCallback(async ({ email, language }) => {
    try {
      // Check State
      if ((authState === CustAuthState.SentMagicLink) || (authState === CustAuthState.ResentMagicLink)) {
        // Check if last magic link sent is within 1 minute
        const intervalAllowed = (authState === CustAuthState.ResentMagicLink) ? 120000 : 60000; // Longer interval for ResentMagicLink
        if (lastMagicLinkSentAt && Date.now() - lastMagicLinkSentAt < intervalAllowed) {
          console.log("[CustAuth-signIn] Magic Link already sent within 1 minute");
          return false;
        }
      }
      const emailParam = email.toLowerCase();
      //console.log("[CustAuth-signIn] emailParam (& language): ", emailParam, language);
      setLastSubmittedEmail(emailParam);
      //const user = callSignIn(emailParam, language);
      callSignIn(emailParam, language);
      // Update state and sent timestamp
      if (authState === CustAuthState.SentMagicLink) {
        setAuthState(CustAuthState.ResentMagicLink);
        setNextMagicLinkAllowedAt(Date.now() + 120000); // 2 minutes
      } else {
        setAuthState(CustAuthState.SentMagicLink);
        setNextMagicLinkAllowedAt(Date.now() + 60000); // 1 minute
      }
      setLastMagicLinkSentAt(Date.now());
      // if (!user) {
      //   console.log("[CustAuth-signIn] Error in callSignIn: ", user);
      //   return false;
      // }
      return true;
    } catch (e) {
      // skip if user already exists
      return false;
    }
  }, []);

  const verify = async (email, code) => {
    try {
      // Sign out any existing user on verification flow
      await Auth.signOut();
      //console.log("[CustAuth-signIn] verify with email: ", email);
      const user = await Auth.signIn(email);
      //console.log("signIn response: ", user);
      if (user?.challengeName === 'CUSTOM_CHALLENGE') {
        try {
          // to send the auth code as the custom challenge answer
          // eslint-disable-next-line no-unused-vars
          const challengeAnswerResponse = await Auth.sendCustomChallengeAnswer(user, code);
          //console.log("[CustAuth-signIn] sendCustomChallengeAnswer response: ", challengeAnswerResponse);
          const authResult = await isAuthenticated();
          if (authResult) {
            setLoggedIn(true);
            setAuthState(CustAuthState.SignedIn);
          } else {
            setLoggedIn(false);
            setAuthState(CustAuthState.Unauthenticated);

            
          }          
          return authResult;
        } catch (err) {
          console.log("[CustAuth-signIn] Error in custom auth sign-in: ", err);
        }
      } else {
        console.log("[CustAuth-signIn] Non custom challenge for user:", user);
      }      
    } catch (err) {
      console.log("Error in verify: ", err);
    }
    
    return isAuthenticated();
  }

  const signOut = React.useCallback(async () => {
    await Auth.signOut();
    setLoggedIn(false);
    setAuthState(CustAuthState.Unauthenticated);
  }, [])

  return (
    <CustAuthContext.Provider
      value={{
        loggedIn,
        setLogout,
        isAuthenticated,
        signIn,
        verify,
        signOut,
        authState, 
        setAuthState,
        lastMagicLinkSentAt, 
        setLastMagicLinkSentAt,
        lastSubmittedEmail,
        nextMagicLinkAllowedAt,
        resetMagicLinkFlow,
      }}
    >
      {props.children}
    </CustAuthContext.Provider>
  )
}

const useAuth = () => React.useContext(CustAuthContext);

export { CustAuthProvider, useAuth }
