import React from "react";
import { Box, Typography } from "@mui/material";

function NotAuthorized() {
  return (
    <>
      <Box>
        {/* <Typography variant="dg_h3">Not authorized to access this page</Typography>                 */}
        <Typography variant="dg_h3"></Typography>
      </Box>
    </>
  );
}

export default NotAuthorized;
