import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/CustAuthContext';

export const ProtectedRoutes = () => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    setTimeout(() => {
        // initial mount or route changed, check token
      isAuthenticated().then((result) => {
        //setIsValid(result);
        //console.log("[ProtectedRoutes] loggedIn: ", result); 
        if (!result) {
          navigate("/login", { replace: true });
          return <></>;
        }
      });
    }, 300);
    
  }, [pathname]);
  return <Outlet/>;
  //return isValid ? <Outlet/> : <Navigate to="/signin" replace />;
}
