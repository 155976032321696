import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Grid, Typography, Button, Box } from "@mui/material";
import imgWaitlist from "../../assets/images/waitlist.png";
import { Auth } from "aws-amplify";
import Footer from "../../components/Footer";

function Waitlist() {
  const { t } = useTranslation();

  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} lg={12} mt={4} container justifyContent={"center"}>
          <img src={imgWaitlist} alt="error" width={"300px"} />
        </Grid>
        <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
          <Typography variant="dg_h1">
            {t("waitlist.general_title")}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
          <Typography variant="dg_body1">
            <Trans i18nKey="waitlist.general_details"
              components={{ support: <a rel="noopener noreferrer" href="mailto:support@gryfyn.io" /> }} />
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
          <Button variant="cta" fullWidth onClick={() => {
            window.open("https://x.com/hondajp_live", "_blank");
          }}
          >
            <Typography variant="dg_button">
              {t("waitlist.cta_button")}
            </Typography>
          </Button>
        </Grid>
        
        <Grid item container xs={12} mt={2} justifyContent={"center"}>
          <Button variant="text" onClick={() => {
            Auth.signOut().then(() => {
              window.location.href = "/";
            }).then(() => {
              window.location.href = "/";
            });
          }}>
            <Typography variant="dg_link" sx={{ textTransform: "none", }}>
              {t("error.back_to_home")}
            </Typography>
          </Button>
        </Grid>
            
      </Grid>

      <Box mx={0} mt={8}>
        <Footer />
      </Box>
    </>
  );
}

export default Waitlist;
