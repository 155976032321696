import React, { Fragment } from "react";
import { Typography, Grid, Card, Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation, Trans } from "react-i18next";
import ImgNewWallet from "../../assets/images/page-created-wallet.png";

// eslint-disable-next-line react/prop-types
function CreatedWallet({handleClose}) {
  const { t } = useTranslation();

  const popupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "350px",
    height: "418px",
    flexShrink: "0",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "2px",    
  };

  const buttonXStyle = {
    position: "absolute",
    top: "5px",
    right: "3px",
    color: "#2B2B2B",
  };

  return (
    <>
      <Card 
        sx={popupStyle}
      >
        <Button variant="text" onClick={() => handleClose()} sx={buttonXStyle}>
          <CloseIcon />
        </Button>
        <Box mx={2} mt={3}>
          <Grid container alignItems={"center"}>
            <Grid item mt={1} xs={12} container justifyContent={"center"}>
              <img src={ImgNewWallet} width={258} alt="New Wallet" />
            </Grid>
            <Grid item mt={1} xs={12} container justifyContent={"center"}>
              <Typography variant="dg_h1">
                {t("created_wallet.title")}
              </Typography>            
            </Grid>
            <Grid item mt={1} xs={12} container justifyContent={"center"}>
              <Typography variant="dg_body1">
                <Trans i18nKey={"created_wallet.body"} components={{ br: <br/> }} />
              </Typography>
            </Grid>
            <Grid item mt={2} container justifyContent={"center"}>
              <Box 
                sx={{
                  display: "flex",
                  width: "310px",
                  height: "48px",                
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  flexShrink: "0",
                  borderRadius: "8px",
                  border: "1px solid var(--primary-red, #C00)",
                }
              }>
                <Button variant="text" onClick={() => handleClose()}>
                  <Typography variant="dg_body" sx={{ fontWeight: "600", color: "var(--primary-red, #C00);" }}>
                    {t("created_wallet.button")}
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
}

export default CreatedWallet;
