import { API } from "aws-amplify";
import { proxywallet } from "../graphql/mutations";

export const callProxyWallet = async (data, success, err) => {
  try {
    const apiRequest = await API.graphql({
      query: proxywallet,
      variables: { input: JSON.stringify(data) },
    });
    //console.log("apiRequest: ", apiRequest);
    const response = JSON.parse(apiRequest.data.proxywallet);
    
    /*
    Response body documentation
    
    */
    if (response.statusCode === 200) {
      const response_body = response.body;
      if (response_body) {
        success(response_body);
      } else {
        err(response_body);
      }
    } else {
      //err(400, `[callProxyWallet] Error in register: ${response.statusCode}`);
      err(response.body);
    }
  } catch (error) {
    //console.log("[callProxyWallet] Error in API call: ", error);
    if (err) {
      err(400, error.toString());
    }
  }
};

export default callProxyWallet;
