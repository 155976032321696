import { createTheme } from "@mui/material/styles";
import { typography_dg_h1, typography_dg_h3, typography_body1, typography_body2, typography_button, typography_link, typography_footer } from "./overrides/typography";
import { button_cta, button_try_other, button_link, button_lng_link, button_social, button_cookie_accept } from "./overrides/button";

export const theme = createTheme({
  typography: {
    dg_h1: typography_dg_h1,
    dg_h3: typography_dg_h3,
    dg_body1: typography_body1,
    dg_body2: typography_body2,
    dg_button: typography_button,
    dg_link: typography_link,
    dg_footer: typography_footer,
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'cta' },
          style: button_cta,
        },
        {
          props: { variant: 'social' },
          style: button_social,
        },
        {
          props: { variant: 'try_other' },
          style: button_try_other,
        },
        {
          props: { variant: 'link' },
          style: button_link,
        },
        {
          props: { variant: 'lng_link' },
          style: button_lng_link,
        },
        {
          props: { variant: 'cookie_accept' },
          style: button_cookie_accept,
        },
      ],
    },
  },
});
