import { API } from "aws-amplify";
import { proxyclaim } from "../graphql/mutations";

export const callProxyClaim = async (data, success, err) => {
  try {
    const apiRequest = await API.graphql({
      query: proxyclaim,
      variables: { input: JSON.stringify(data) },
    });
    //console.log("apiRequest: ", apiRequest);
    const response = JSON.parse(apiRequest.data.proxyclaim);
    
    /*
    Response body documentation
    
    */
    if (response.statusCode === 200) {
      const response_body = response.body;
      if (response_body) {
        success(response_body);
      } else {
        err(response_body);
      }
    } else {
      err(400, `[callProxyClaim] Error in claim NFT: ${response.statusCode}: ${response.body}`);
    }
  } catch (error) {
    //console.log("[callProxyClaim] Error in API call: ", error);
    if (err) {
      err(400, error.toString());
    }
  }
};

export default callProxyClaim;
