import React from "react";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";

const SignoutLink = () => {
  const { t } = useTranslation();
  return (    
    <Button variant="link" 
      onClick={async () => {        
        await Auth.signOut();
        window.location.href = "/";
      }}>
        <Typography variant="dg_b1">
          {t("signout")}
        </Typography>
    </Button>
  );
};

export default SignoutLink;