import React from "react";
import { Hub, Auth, Analytics, DataStore } from "aws-amplify";
//import { useSearchParams, useLocation } from "react-router-dom";
import { useAuth } from "./CustAuthContext";
import { makeSessionId } from "../assets/utilities/makeSessionId";

// Analytics
import { addHours, STREAM_NAME } from "../functions/log_event_analytics";

// Google Analytics - GA4
import ReactGA from 'react-ga4';

// API Functions
import callProxyWallet from "../functions/callProxyWallet";
import callProxyAsset from "../functions/callProxyAsset";
import callProxyClaim from "../functions/callProxyClaim";
import callUpdateWaitlist from "../functions/callUpdateWaitlist";

import { useCookies } from 'react-cookie';
import { AES, enc } from "crypto-js";

// Model
import { DGF1JP2023ChampionshipQuest } from "../models";


export const MainContext = React.createContext({
  sessionId: "",
  setSessionId: () => {},
  updateSessionId: () => {},
  userEmail: "",
  setUserEmail: () => {},
  userSignInMethod: "",
  setUserSignInMethod: () => {},
  loadingWallet: false,
  setLoadingWallet: () => {},
  walletAddress: "",
  setWalletAddress: () => {},
  isNewWallet: false,
  setIsNewWallet: () => {},
  userAgent: "",
  setUserAgent: () => {},
  pathname: "",
  setPathname: () => {},
  language: "ja",
  setLanguage: () => {},
  referrerCode: "",
  setReferrerCode: () => {},
  isMobile: false,
  setIsMobile: () => {},
  browserName: "",
  setBrowserName: () => {},
  userIpVersion: "",
  setUserIpVersion: () => {},
  userIp: "",
  setUserIp: () => {},
  userCountryCode: "",
  setUserCountryCode: () => {},
  userCountryName: "",
  setUserCountryName: () => {},
  userCity: "",
  setUserCity: () => {},
  userTimezone: "",
  setUserTimezone: () => {},
  userLatitude: "",
  setUserLatitude: () => {},
  userLongitude: "",
  setUserLongitude: () => {},
  userIpReady: false,
  setUserIpReady: () => {},

  gaCampaignId: "",
  setGaCampaignId: () => {},
  gaCampaign: "",
  setGaCampaign: () => {},
  gaSource: "",
  setGaSource: () => {},
  gaMedium: "",
  setGaMedium: () => {},
  gaTerm: "",
  setGaTerm: () => {},
  gaContent: "",
  setGaContent: () => {},


  isWalletAssetLoaded: false,
  setIsWalletAssetLoaded: () => {},
  walletAsset: [],
  setWalletAsset: () => {},

  hondaEventUrl: "",
  setHondaEventUrl: () => {},
  homeRouteTo: "",
  setHomeRouteTo: () => {},

  // Honda KEY NFT Claim Flow
  claimHondaKeyNFT: () => {},
  onClaimHondaKeyCompleted: () => {},
  claimState: "",
  setClaimState: () => {},
  isClaimCompleted: false,
  setIsClaimCompleted: () => {},
  contractAddressLookup: [],

  // Referral register email
  referralRegisterEmail: "",
  setReferralRegisterEmail: () => {},
  fillRegisterEmail: () => {},
  isForceSameEmailRegister: false,

  // For QRCode Claim Process
  isRedirectClaimPage: false,
  setIsRedirectClaimPage: () => {},
  redirectClaimEventId: "",
  setRedirectClaimEventId: () => {},
  updateRedirectClaimEventId: () => {},
  updateCompleteClaimEvent: () => {},
  claimScannedNFT: () => {},
  onClaimScannedCompleted: () => {},

  // Analytics function
  log_event_analytics: () => {},
});

// eslint-disable-next-line react/prop-types
export const MainProvider = ({ children }) => {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies([
    'sid',
    'ga_utm_campaign_id', 'ga_utm_campaign', 'ga_utm_campaign_source', 
    'ga_utm_campaign_medium', 'ga_utm_campaign_term', 'ga_utm_campaign_content',
    'nft_claim_state', 'applicant', 
    'redirect_claim', 'claim_event_id',
  ]);
  const fromCookieOrNew = () => {
    if (cookies.sid !== undefined) {
      //console.log("Reusing cookie sid:", cookies.sid);
      return cookies.sid;
    } else {
      const newSessionId = makeSessionId(10);
      // MaxAge set to 10 Hours
      setCookie('sid', newSessionId, { path: '/', maxAge: 60 * 60 * 10 });
      //console.log("New cookie sid:", newSessionId);
      return newSessionId;
    }
  }
  const [sessionId, setSessionId] = React.useState(fromCookieOrNew());
  const [userEmail, setUserEmail] = React.useState("");
  const [userSignInMethod, setUserSignInMethod] = React.useState("");
  const [loadingWallet, setLoadingWallet] = React.useState(false);
  const [walletAddress, setWalletAddress] = React.useState("");
  const [isNewWallet, setIsNewWallet] = React.useState(false);
  const [userAgent, setUserAgent] = React.useState("");
  const [pathname, setPathname] = React.useState("");
  const [language, setLanguage] = React.useState("ja");
  const [referrerCode, setReferrerCode] = React.useState("");
  const [isMobile, setIsMobile] = React.useState(false);
  const [browserName, setBrowserName] = React.useState("");
  const [userIpVersion, setUserIpVersion] = React.useState("");
  const [userIp, setUserIp] = React.useState("");
  const [userCountryCode, setUserCountryCode] = React.useState("");
  const [userCountryName, setUserCountryName] = React.useState("");
  const [userCity, setUserCity] = React.useState("");
  const [userTimezone, setUserTimezone] = React.useState("");
  const [userLatitude, setUserLatitude] = React.useState("");
  const [userLongitude, setUserLongitude] = React.useState("");
  const [userIpReady, setUserIpReady] = React.useState(false);

  const [gaCampaignId, setGaCampaignId] = React.useState((cookies?.ga_utm_campaign_id !== undefined ? cookies.ga_utm_campaign_id : ""));
  const [gaCampaign, setGaCampaign] = React.useState((cookies?.ga_utm_campaign !== undefined ? cookies.ga_utm_campaign : ""));
  const [gaSource, setGaSource] = React.useState((cookies?.ga_utm_campaign_source !== undefined ? cookies.ga_utm_campaign_source : ""));
  const [gaMedium, setGaMedium] = React.useState((cookies?.ga_utm_campaign_medium !== undefined ? cookies.ga_utm_campaign_medium : ""));
  const [gaTerm, setGaTerm] = React.useState((cookies?.ga_utm_campaign_term !== undefined ? cookies.ga_utm_campaign_term : ""));
  const [gaContent, setGaContent] = React.useState((cookies?.ga_utm_campaign_content !== undefined ? cookies.ga_utm_campaign_content : ""));

  const [isWalletAssetLoaded, setIsWalletAssetLoaded] = React.useState(false);
  const [walletAsset, setWalletAsset] = React.useState({ nft: []});

  // eslint-disable-next-line no-undef
  const [hondaEventUrl, setHondaEventUrl] = React.useState(process.env.REACT_APP_HONDA_EVENT_URL || "http://localhost:3000");
  const [homeRouteTo, setHomeRouteTo] = React.useState("");

  // Honda KEY NFT Claim Flow
  const [claimState, setClaimState] = React.useState((cookies?.nft_claim_state !== undefined ? cookies.nft_claim_state : ""));
  const [isClaimCompleted, setIsClaimCompleted] = React.useState(false);
  const [contractAddressLookup, setContractAddressLookup] = React.useState({honda: [], suzuka: []});
  // eslint-disable-next-line no-undef, no-unused-vars
  const [hondaKeyNFTEventId, setHondaKeyNFTEventId] = React.useState(process.env.REACT_APP_HONDA_KEY_EVENT_ID || "");

  // Register email from referral link
  // eslint-disable-next-line no-undef, no-unused-vars
  const [applicantSecretPhase, setApplicantSecretPhase] = React.useState(process.env.REACT_APP_AES_SECRET_PHASE || "mr4m8RYrKemv7H2yaa5kRS4hEwjAeuXYqpmmK5l3vsV2Td7o");
  const [referralRegisterEmail, setReferralRegisterEmail] = React.useState("");
  const [isForceSameEmailRegister, setIsForceSameEmailRegister] = React.useState(false);

  // For QRCode Claim Process
  const [isRedirectClaimPage, setIsRedirectClaimPage] = React.useState(false);
  const [redirectClaimEventId, setRedirectClaimEventId] = React.useState("");

  const { setLogout, isAuthenticated } = useAuth();
  // Internal state to control Proxy wallet loading
  // Flag to indicate whether to load wallet: default is true
  const [requireLoadWallet, setRequireLoadWallet] = React.useState(true);

  // Update Session ID
  const updateSessionId = (overrideSid) => {
    if (overrideSid !== undefined) {
      // Log Analytics
      log_event_analytics("override_sid", { "old_session_id": sessionId, "new_session_id": overrideSid });

      // Override session id
      setSessionId(overrideSid);
      setCookie('sid', overrideSid, { path: '/', maxAge: 60 * 60 * 10 });
    }
  }

  React.useEffect(() => {
    if (userIpReady) {
      log_event_analytics("user_ip_located", { path: pathname });
    }
  }, [userIpReady]);

  // Analytics: log_event function
  const log_event_analytics = (event_name, attributes) => {
    try {
      if (sessionId !== "") {
        let analytics_attributes = attributes;
        if ((event_name === "pageview") || (event_name === "returned_user") || (event_name.startsWith("signin"))) {
          if (gaCampaignId !== "") {
            analytics_attributes = { ...analytics_attributes, "ga_campaign_id": gaCampaignId };
          }
          if (gaCampaign !== "") {
            analytics_attributes = { ...analytics_attributes, "ga_campaign": gaCampaign };
          }
          if (gaSource !== "") {
            analytics_attributes = { ...analytics_attributes, "ga_source": gaSource };
          }
          if (gaMedium !== "") {
            analytics_attributes = { ...analytics_attributes, "ga_medium": gaMedium };
          }
          if (gaTerm !== "") {
            analytics_attributes = { ...analytics_attributes, "ga_term": gaTerm };
          }
          if (gaContent !== "") {
            analytics_attributes = { ...analytics_attributes, "ga_content": gaContent };
          }
        }
        // eslint-disable-next-line no-undef
        const env = process.env.REACT_APP_BUILD_ENV || "dev";
        // eslint-disable-next-line no-undef
        const corporate_event = process.env.REACT_APP_ANALYTICS_CORPORATE_EVENT || "default";
        const d_local = new Date();
        const d_utc = new Date(
          Date.UTC(
            d_local.getUTCFullYear(),
            d_local.getUTCMonth(),
            d_local.getUTCDate(),
            d_local.getUTCHours(),
            d_local.getUTCMinutes(),
            d_local.getUTCSeconds()
          )
        );
        const d_rpt = addHours(d_utc, 8);
        const log_data = {
          corporate_event: corporate_event,
          session_id: sessionId,
          user_email: userEmail,
          user_sign_in_method: userSignInMethod,        
          user_agent: userAgent,
          log_datetime: d_utc.toISOString(),
          local_datetime: d_rpt.toISOString(),
          is_mobile: isMobile,
          brower_name: browserName,
          user_ip_version: userIpVersion,
          user_ip: userIp,
          user_country_code: userCountryCode,
          user_country_name: userCountryName,
          user_city: userCity,
          user_timezone: userTimezone,
          user_latitude: userLatitude,
          user_longitude: userLongitude,
          language: language,
          event_name: event_name,
          attributes: JSON.stringify(analytics_attributes),
          year: d_rpt.getUTCFullYear().toString().padStart(2, "0"),
          month: (d_rpt.getUTCMonth() + 1).toString().padStart(2, "0"),
          day: d_rpt.getUTCDate().toString().padStart(2, "0"),
          hour: d_rpt.getUTCHours().toString().padStart(2, "0"),
        };
        Analytics.record(
          {
            data: log_data,
            partitionKey: "session_id",
            streamName: `${STREAM_NAME}-${env}`,
          },
          "AWSKinesis"
        );
        //console.log("log_event_analytics: ", log_data);
      }    
    } catch (error) {
      console.log("Error in log_event_analytics: ", error);
    }
  };

  const claimHondaKeyNFT = () => {
    // Function to trigger Honda KEY NFT claim at backend
    //console.log("[claimHondaKeyNFT] for wallet ", walletAddress);

    // Change State to Submitted
    try {
      setIsClaimCompleted(false);
      // eslint-disable-next-line no-unused-vars
      callProxyClaim({"event_id": hondaKeyNFTEventId}, (claim_data) => {
          //console.log("Submitted Claim Honda KEY NFT: ", claim_data);
        }, (claim_err) => {
          console.log("Error in claimHondaKeyNFT: ", claim_err);
        });
      setCookie('nft_claim_state', "submitted", { path: '/', maxAge: 60 * 10 });
      setClaimState("submitted");

      // Log event to Analytics
      log_event_analytics("claim_honda_key_nft", { "wallet_address": walletAddress });
      ReactGA.event("claim_honda_key_nft", { wallet_address: walletAddress});
    } catch (claim_err) {
      console.log("Error in claimHondaKeyNFT: ", claim_err);
      log_event_analytics("error_claim_honda_key_nft", { "wallet_address": walletAddress, "error": `${claim_err}` });
    }
  };

  const onClaimHondaKeyCompleted = () => {
    // Log event analytics
    log_event_analytics("claim_honda_key_nft_completed", { "email": userEmail, "wallet_address": walletAddress });
    ReactGA.event("claim_honda_key_nft_completed", { wallet_address: walletAddress});
    
    removeCookie('nft_claim_state', { path: '/' });
    setClaimState("");
    setIsClaimCompleted(true);
  };

  // Set Register Email
  const fillRegisterEmail = (email) => {
    // Using Crypto AES to encrypt email address and store in cookies
    const encryptedEmail = AES.encrypt(email, applicantSecretPhase).toString();    
    // Only for 10min for applicant
    setCookie('applicant', encryptedEmail, { path: '/', maxAge: 60 * 10});
    setReferralRegisterEmail(email);
    if (isForceSameEmailRegister) {
      setIsForceSameEmailRegister(false);
      setIsForceSameEmailRegister(true);
    } else {
      setIsForceSameEmailRegister(true);
    }
    
    // Log Event Analytics
    log_event_analytics("applicant-register", { "applicant": email });

    // GA
    ReactGA.event("applicant-register", { });
  };

  // For QRCode Claim Process
  const updateRedirectClaimEventId = (eventId) => {
    setIsRedirectClaimPage(true);
    setRedirectClaimEventId(eventId);
    setCookie('redirect_claim', true, { path: '/' });
    setCookie('claim_event_id', eventId, { path: '/' });
  };
  const updateCompleteClaimEvent = () => {
    setIsRedirectClaimPage(false);
    removeCookie('redirect_claim', { path: '/' });
    removeCookie('claim_event_id', { path: '/' });
    setRedirectClaimEventId("");
  };

  const claimScannedNFT = () => {
    // Function to trigger Honda KEY NFT claim at backend
    //console.log("[claimScannedNFT] for wallet ", walletAddress, " and event id ", redirectClaimEventId);

    // Change State to Submitted
    try {
      if (redirectClaimEventId !== undefined && redirectClaimEventId !== "") {
        //setIsClaimCompleted(false);
        // eslint-disable-next-line no-unused-vars
        callProxyClaim({"event_id": redirectClaimEventId}, (claim_data) => {
            //console.log("Submitted Claim Honda KEY NFT: ", claim_data);
          }, (claim_err) => {
            console.log("Error in claimScannedNFT: ", claim_err);
          });
        //setCookie('nft_claim_state', "submitted", { path: '/' });
        //setClaimState("submitted");

        // Log event to Analytics
        log_event_analytics("claim_scanned_nft", { "wallet_address": walletAddress, "event_id": redirectClaimEventId });
        ReactGA.event("claim_scanned_nft", { wallet_address: walletAddress, "event_id": redirectClaimEventId});

        // Mark claim flow triggering as completed
        updateCompleteClaimEvent();
      }
    } catch (claim_err) {
      console.log("Error in claimScannedNFT: ", claim_err);
      log_event_analytics("error_claim_scanned_nft", { "wallet_address": walletAddress, "event_id": redirectClaimEventId, "error": `${claim_err}` });
    }
  };

  const onClaimScannedCompleted = () => {
    // Log event analytics
    log_event_analytics("claim_scanned_nft_completed", { "email": userEmail, "wallet_address": walletAddress });
    ReactGA.event("claim_scanned_nft_completed", { wallet_address: walletAddress});
    
    //removeCookie('nft_claim_state', { path: '/' });
    //setClaimState("");
    //setIsClaimCompleted(true);
  };


  // Handlers for Hub
  const onProxyWalletSuccess = (data) => {
    //console.log("onProxyWalletSuccess: ", data);
    setLoadingWallet(false);
    const json_data = JSON.parse(data);
    if (json_data.wallet_address !== undefined) {
      setWalletAddress(json_data.wallet_address);
      let isNewWallet = false;
      if (json_data.is_new_wallet) {
        setIsNewWallet(true);
        isNewWallet = true;

        // Log analytics for new wallet created
        log_event_analytics("created_new_wallet", { "email": json_data.email, "wallet_address": json_data.wallet_address });
      }
      // For development testing -> always set New Wallet to true
      // setIsNewWallet(true);
      // isNewWallet = true;
      

      // Call Proxy Asset API
      callProxyAsset({"wallet_address": json_data.wallet_address}, (asset_data) => {
      //callProxyAsset({"wallet_address": "0x03419c927649bfc1d9962637febf6c50ffa787e4"}, (asset_data) => {
        
        setIsWalletAssetLoaded(true);
        setWalletAsset({ nft: JSON.parse(asset_data)});
        
      }, (asset_err) => {
        log_event_analytics("error_proxy_asset", { "wallet_address": json_data.wallet_address, "error": asset_err });
        console.log("Error in callProxyAsset: ", asset_err);
      });

      // Page routing from Home
      if (isNewWallet) {
        //console.log("New Wallet");
        //setHomeRouteTo("/welcome");
        setHomeRouteTo("/profile");
      } else {
        setHomeRouteTo("/profile");
      }
    }
    
  };

  // eslint-disable-next-line no-unused-vars
  const onProxyWalletFailure = (data) => {
    //console.log("onProxyWalletFailure: ", data);
    // if "Not Found" in data, i.e. redirect to waitlist
    if (data.includes("Not Found")) {
      //console.log("Redirect to waitlist");
      // Cannot use setHomeRoute as it is for logged in
      setLoadingWallet(false);
      setHomeRouteTo("/waitlist");
      // Update waitlist
      // eslint-disable-next-line no-unused-vars
      callUpdateWaitlist({email: userEmail}).then((waitlist_data) => {
        //console.log("Waitlist updated: ", waitlist_data);
      // eslint-disable-next-line no-unused-vars
      }).catch((waitlist_err) => {
        //console.log("Error in callUpdateWaitlist: ", waitlist_err);
      });
    } else {
      log_event_analytics("error_proxy_wallet", { "email": userEmail, "error": data });
      setLoadingWallet(false);
      setHomeRouteTo("/error");
    }
  };

  const checkAndCallProxyWallet = () => {
    if (requireLoadWallet) {
      //console.log("Loading wallet for the current user");
      setLoadingWallet(true);
      callProxyWallet({}, onProxyWalletSuccess, onProxyWalletFailure);
      setRequireLoadWallet(false);
    } //else {
    //  console.log("No need to load wallet for the current user");
    //}
  };

  React.useEffect(() => {
    // Read Contract Address Lookup at initial page load
    // eslint-disable-next-line no-undef
    const dataContractAddress = process.env.REACT_APP_HONDA_KEY_CONTRACT_ADDRESSES || "";
    const dataContractAddressArray = dataContractAddress.split(",");

    // eslint-disable-next-line no-undef
    const dataContractAddressSuzuka = process.env.REACT_APP_SUZUKA_CIRCUIT_CONTRACT_ADDRESSES || "";
    const dataContractAddressSuzukaArray = dataContractAddressSuzuka.split(",");
    setContractAddressLookup({honda: dataContractAddressArray, suzuka: dataContractAddressSuzukaArray});

    // Read any applicant email (encrypted) from cookies, and decrypt if any
    const encryptedEmail = cookies?.applicant;
    let decryptedEmail = "";
    if (encryptedEmail !== undefined) {
      try {
        const decryptedEmailBytes = AES.decrypt(encryptedEmail, applicantSecretPhase);
        decryptedEmail = decryptedEmailBytes.toString(enc.Utf8);
        setReferralRegisterEmail(decryptedEmail);
        setIsForceSameEmailRegister(true);
      } catch (decryptErr) {
        console.log("Error in decrypting applicant email: ", decryptErr);
      }
    }

    // Scanned QR Code for event claim
    // const c_redirect_claim = cookies?.redirect_claim;
    // const c_claim_event_id = cookies?.claim_event_id;
    // if (c_redirect_claim !== undefined && c_redirect_claim === true) {
    //   setIsRedirectClaimPage(true);
    // }
    // if (c_claim_event_id !== undefined && c_claim_event_id !== "") {
    //   setRedirectClaimEventId(c_claim_event_id);
    // }

    Auth.currentAuthenticatedUser().then((user) => {
      //console.log("MainContext init check user: ", user);
      if (user && user.attributes && user.attributes.email) {
        setUserEmail(user.attributes.email);

        // Log analytics for returned user
        log_event_analytics("returned_user", { "email": user.attributes.email });

        // calling API for Proxy Wallet only after the user email is retrieved
        checkAndCallProxyWallet();
      }
    })
    // eslint-disable-next-line no-unused-vars
    .catch((err) => {
      // User is not authenticated on initial page load
      //console.log("Error: ", err);
      setIsWalletAssetLoaded(false);
      setWalletAsset([]);
    });

    //console.log("Listening to auth events");
    const listener = Hub.listen("auth", (data) => {
      //console.log("auth-listener: ", data);
      switch (data.payload.event) {
        case "signIn":          
          //console.log("signIn: ", data.payload.data);
          Auth.currentAuthenticatedUser().then((user) => {
            //console.log("signIn user: ", user);
            // Log event analytics for signIn user
            try {
              if (user.attributes?.identities) {
                const signInIdentities = JSON.parse(user.attributes.identities);                
                if (signInIdentities.providerName !== undefined) {
                  if (signInIdentities.providerName === "Google") {
                    log_event_analytics("signin-google", { "email": user.attributes.email, "provider": "Google" });
                  } else {
                    log_event_analytics("signin-other", { "email": user.attributes.email, "provider": signInIdentities.providerName });
                  }
                }
              } else {
                // Email via Cognito cust auth
                log_event_analytics("signin-email", { "email": user.attributes.email, "provider": "Internal" });
              }
              ReactGA.event("login", { method: (user.attributes.identities !== undefined ? "Google" : "Email"), email: user.attributes.email});

              // If there is any referral register email, send another log event
              if (decryptedEmail !== "") {
                try {
                  //console.log("Referral Register Email: ", decryptedEmail);
                  log_event_analytics("applicant-register-signin", { "email": user.attributes.email, "applicant": decryptedEmail });
                  ReactGA.event("applicant-register-signin", {});

                  // Save to DataStore for a record of applicant-register-signin
                  DataStore.save(new DGF1JP2023ChampionshipQuest({ email: user.attributes.email, gameEmail: decryptedEmail }));

                  // Remove from cookies
                  removeCookie('applicant', { path: '/' });
                  setIsForceSameEmailRegister(false);
                } catch (applicantErr) {
                  console.log("Error in applicant-register-signin: ", applicantErr);
                  log_event_analytics("error_applicant_register_signin", { "email": user.attributes.email, "applicant": decryptedEmail, "error": `${applicantErr}` });
                }
              }
            } catch (log_err) {
              console.log("Error in (signIn) log_event_analytics: ", log_err);
            }

            isAuthenticated();
            if (user && user.attributes && user.attributes.email) {
              setUserEmail(user.attributes.email);

              // calling API for Proxy Wallet only after the user email is retrieved
              checkAndCallProxyWallet();
            }
          });
          
          break;
        case "parsingCallbackUrl":
          //console.log("parsingCallbackUrl: ", data.payload.data);          
          // calling API for Proxy Wallet
          // setLoadingWallet(true);
          // callProxyWallet({}, onProxyWalletSuccess, onProxyWalletFailure);
          break;
        case "signOut":
          // Log analytics
          try {
            log_event_analytics("signout", { "email": userEmail });
            ReactGA.event("logout", { email: userEmail});
          } catch (log_err) {
            console.log("Error in (signOut) log_event_analytics: ", log_err);
          }
          //setUser(null);
          //console.log("User signing out -> Reset");
          setRequireLoadWallet(true);
          setUserEmail("");
          setUserSignInMethod("");
          setLoadingWallet(false);
          setWalletAddress("");
          setIsNewWallet(false);
          setLogout();
          setIsWalletAssetLoaded(false);
          setWalletAsset([]);
          //window.location.href = "/";          
          break;
        case "customOAuthState":
          //console.log("customOAuthState: ", data.payload.data);
          break;
        default:
          break;
      }
    });
    
    return () => {
      // Remove Hub listener for disposal
      listener();
    }
  }, []);

  return (
    <MainContext.Provider
      value={{
        sessionId,
        setSessionId,
        updateSessionId,
        userEmail,
        setUserEmail,
        userSignInMethod,
        setUserSignInMethod,
        loadingWallet,
        setLoadingWallet,
        walletAddress,
        setWalletAddress,
        isNewWallet,
        setIsNewWallet,
        userAgent,
        setUserAgent,
        pathname,
        setPathname,
        language,
        setLanguage,
        referrerCode,
        setReferrerCode,
        isMobile,
        setIsMobile,
        browserName,
        setBrowserName,
        userIpVersion,
        setUserIpVersion,
        userIp,
        setUserIp,
        userCountryCode,
        setUserCountryCode,
        userCountryName,
        setUserCountryName,
        userCity,
        setUserCity,
        userTimezone,
        setUserTimezone,
        userLatitude,
        setUserLatitude,
        userLongitude,
        setUserLongitude,
        userIpReady,
        setUserIpReady,

        gaCampaignId,
        setGaCampaignId,
        gaCampaign,
        setGaCampaign,
        gaSource,
        setGaSource,
        gaMedium,
        setGaMedium,
        gaTerm,
        setGaTerm,
        gaContent,
        setGaContent,

        isWalletAssetLoaded,
        setIsWalletAssetLoaded,
        walletAsset,
        setWalletAsset,

        hondaEventUrl,
        setHondaEventUrl,
        homeRouteTo,
        setHomeRouteTo,

        // Honda KEY NFT Claim Flow
        claimHondaKeyNFT,
        onClaimHondaKeyCompleted,
        claimState,
        setClaimState,
        isClaimCompleted,
        setIsClaimCompleted,
        contractAddressLookup, 
        setContractAddressLookup,

        // Referral register email
        referralRegisterEmail,
        setReferralRegisterEmail,
        fillRegisterEmail,
        isForceSameEmailRegister,

        // For QRCode Claim Process
        isRedirectClaimPage,
        setIsRedirectClaimPage,
        redirectClaimEventId,
        setRedirectClaimEventId,
        updateRedirectClaimEventId,
        updateCompleteClaimEvent,
        claimScannedNFT,
        onClaimScannedCompleted,

        log_event_analytics,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
