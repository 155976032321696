import React from "react";
import { Box, CircularProgress, Typography, Button, Grid } from "@mui/material";
import { MainContext } from "../../contexts/MainContext";
import { Widget } from '@typeform/embed-react';
import { DataStore } from "aws-amplify";
import { API, graphqlOperation } from 'aws-amplify';
import { listDGF1JP2023FormResponses } from '../../graphql/queries';
import { DGF1JP2023FormResponse } from "../../models";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
// Google Analytics - GA4
import ReactGA from 'react-ga4';

function Quiz() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mainContext = React.useContext(MainContext);
  const [quizFormId, setQuizFormId] = React.useState("");
  const [showQuiz, setShowQuiz] = React.useState(false);
  const [showFilled, setShowFilled] = React.useState(false);
  //const [showHomeLink, setShowHomeLink] = React.useState(true);
  const handleResponse = async (event) => {
    //console.log(event);
    // Persist response to Storage via API
    if (event?.responseId !== undefined) {
      const responseId = event.responseId;
      //const responseItem = await DataStore.save(
      await DataStore.save(
        new DGF1JP2023FormResponse({
          email: mainContext.userEmail,
          formId: quizFormId,
          walletAddress: mainContext.walletAddress,
          responseId: responseId,
        })
      );

      // Log Analytics
      mainContext.log_event_analytics("quiz_filled", { 
        email: mainContext.userEmail, 
        formId: quizFormId, 
        walletAddress: mainContext.walletAddress, 
        responseId: responseId });

      // GA Custom Event
      ReactGA.event("quiz_filled", {
        formId: quizFormId, 
        walletAddress: mainContext.walletAddress, 
        responseId: responseId
      });

      //console.log('ResponseItem saved successfully!', responseItem);
      //setShowFilled(false);
      //setShowQuiz(true);
      //setShowHomeLink(true);
    }
  };  
  const handleEndingButtonClick = (event) => {
    console.log(event);
  };

  // React.useEffect(() => {
  //   // Parse Form ID from environment variables
  //   // Expected variables for REACT_APP_HONDA_F1_QUIZ_FORMS={language_code}:{form_id},{language_code}:{form_id},...
  //   // eslint-disable-next-line no-undef
  //   // const quizForms = process.env.REACT_APP_HONDA_F1_QUIZ_FORMS || "";
  //   // const quizFormsArray = quizForms.split(",");
  //   // const langFormId = quizFormsArray.find((e) => e.startsWith(mainContext.language));
  //   // const formId = (langFormId) ? langFormId.split(":")[1] : "";
  //   // setQuizFormId(formId);

    
  //   //console.log("[Quiz] form id=", formId);
    
  // }, [mainContext.language]);

  React.useEffect(() => {
    // Parse Form ID from environment variables
    // Expected variables for REACT_APP_HONDA_F1_QUIZ_FORMS={form_id}
    // eslint-disable-next-line no-undef
    const formId = process.env.REACT_APP_HONDA_F1_QUIZ_FORMS;
    setQuizFormId(formId);
  }, []);

  React.useEffect(() => {
    // Query any response filled by the user given the form id
    try {
      if (quizFormId !== "") {
        // DataStore.query(DGF1JP2023FormResponse, (r) => r.and(r => [
        //   r.email.eq(mainContext.userEmail), 
        //   r.formId.eq(quizFormId),
        // ])).then((data) => {
        //   //console.log("[Quiz] Data store: ", data);
        //   if (data.length > 0) {
        //     // Previous response found
        //     setShowFilled(true);
        //     setShowQuiz(false);
        //     //setShowHomeLink(true);
        //   } else {
        //     setShowFilled(false);
        //     setShowQuiz(true);
        //     //setShowHomeLink(false);

        //     // Log Analytics
        //     mainContext.log_event_analytics("quiz_attempt", { 
        //       email: mainContext.userEmail, 
        //       formId: quizFormId, 
        //       walletAddress: mainContext.walletAddress, 
        //     });

        //     // GA Custom Event
        //     ReactGA.event("quiz_attempt", {
        //       formId: quizFormId, 
        //       walletAddress: mainContext.walletAddress,
        //     });
        //   }
        // }).catch((error) => {
        //   console.log("[Quiz] error in DataStore query: ", error);
        // });

        // Use GraphQL API instead of DataStore
        API.graphql(graphqlOperation(listDGF1JP2023FormResponses, {
          filter: {
            formId: {
              eq: quizFormId,
            },
          }
        })).then((data) => {
          //console.log("[Quiz] GraphQL: ", data);
          const dataItems = data.data.listDGF1JP2023FormResponses.items;
          if (dataItems !== undefined && dataItems.length > 0) {
            // Previous response found
            setShowFilled(true);
            setShowQuiz(false);
            //setShowHomeLink(true);
          } else {
            setShowFilled(false);
            setShowQuiz(true);
            //setShowHomeLink(false);

            // Log Analytics
            mainContext.log_event_analytics("quiz_attempt", { 
              email: mainContext.userEmail, 
              formId: quizFormId, 
              walletAddress: mainContext.walletAddress, 
            });

            // GA Custom Event
            ReactGA.event("quiz_attempt", {
              formId: quizFormId, 
              walletAddress: mainContext.walletAddress,
            });
          }
        }).catch((error) => {
          console.log("[Quiz] error in API query: ", error);
        });
      }
    } catch (error) {
      console.log("[Quiz] error in API query: ", error);
    }  

  }, [quizFormId]);

  return (
    <>
      <Box>
        {(!showQuiz && !showFilled) && (
          <Box sx={{ display: 'flex', height: "72vh" }} alignItems={"center"}>
            <CircularProgress />
          </Box>
        )}
        {showQuiz && (
        <Widget style={{ width: "100%", height: "72vh" }}
          id={quizFormId}
          hidden={{
            wallet_address: mainContext.walletAddress,
            language: mainContext.language,
          }}
          enableSandbox={false}
          inlineOnMobile={true}
          onSubmit={handleResponse}
          onEndingButtonClick={handleEndingButtonClick}
         />
        )}
        {showFilled && (
          <Box sx={{ display: 'flex', height: "32vh" }} mt={4} alignItems={"flex-end"} justifyContent={"center"}>
            <Grid container>
              <Grid item xs={12} justifyContent={"center"} container>
                <Typography variant="dg_body1">
                  <Trans i18nKey="profile.quiz.filled"
                    components={{
                      br: <br />,
                      b: <strong />,
                    }}
                  />
                </Typography>
              </Grid>              
            </Grid>
          </Box>
        )}
        <Box mt={2} sx={{ display: 'flex' }} alignItems={"center"} justifyContent={"center"}>
          <Grid container>              
            <Grid item xs={12} justifyContent={"center"} container>
              <Button variant="text"
                sx={{ textTransform: "none" }}
                onClick={() => {
                navigate("/profile");
              }}
              >
                <Typography sx={{ textDecoration: "underline" }} variant="dg_body2">
                  {t("error.back_to_home")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Quiz;
