import React from "react";
import { theme } from "../../assets/themes";
import { Grid, Box, Stack, Button, Typography, ThemeProvider } from "@mui/material";
//import { useMediaQuery } from 'react-responsive';
import { MainContext } from "../../contexts/MainContext";
import { useTranslation, Trans } from "react-i18next";
import { I18n } from 'aws-amplify';
//import { Auth } from "aws-amplify";
//import { Link } from "@aws-amplify/ui-react";
import { useCookies } from 'react-cookie';

function Footer() {
  //const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const mainContext = React.useContext(MainContext);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['accept_cookies']);
  //const [showCookieAccept, setShowCookieAccept] = React.useState(cookies && cookies.accept_cookies !== true);
  const [showCookieAccept, setShowCookieAccept] = React.useState(false);
  const { i18n } = useTranslation();

  // React.useEffect(() => {
  //   console.log("[Footer] cookies: ", cookies);
  // }, [cookies]);

  const changeLanguageTo = (lng) => {
    //console.log("changeLanguageTo: ", lng);
    mainContext.setLanguage(lng);
    mainContext.log_event_analytics("change_language", { language_to: lng });    
    i18n.changeLanguage(lng);
    I18n.setLanguage(lng);
  };

  React.useState(() => {
    // Set detected language from i18n to mainContext language
    // Extract first two characters from detected language
    const detectedLanguage = (i18n.language) ? i18n.language.substring(0, 2) : "ja";
    //console.log("initially detected language: ", detectedLanguage);
    mainContext.setLanguage(detectedLanguage);
    I18n.setLanguage(detectedLanguage);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box mx={1}>        
        <Grid container alignItems={"center"}>
          {(showCookieAccept) && (
            <Grid item xs={12} lg={12} mb={1} container justifyContent={"center"}>
              <Grid container justifyContent={"center"}>
                <Grid item xs={8} lg={8} container justifyContent={"center"}>
                  <Typography variant="dg_footer" size="sm" fontWeight="light">
                    <Trans i18nKey="footer.cookie.text"
                      components={{
                        ck: <a href="https://www.iubenda.com/privacy-policy/51877991/legal" target="_blank" rel="noreferrer" />,
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={4} lg={4} container justifyContent={"center"} alignContent={"center"}>
                  <Button
                    rel="noreferrer"
                    variant="cookie_accept"
                    onClick={() => {
                      setCookie('accept_cookies', true, { path: '/' });
                      setShowCookieAccept(false);
                      mainContext.log_event_analytics("accept_cookies", { accept_cookies: true });
                    }}
                  >
                    <Typography
                      variant="dg_footer"
                      fontWeight="normal"
                      sx={{
                        color: "white",
                        fontSize: "10px"
                        // fontSize: "0.75rem",
                        // textDecoration: "underline",
                      }}
                    >
                      {i18n.t("footer.cookie.button")}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={6} lg={6} container justifyContent={"flex-start"}>
            <Typography variant="dg_footer" ml={1}>© 2023 Gryfyn Ltd.</Typography>
          </Grid>
          <Grid item xs={6} lg={6} container justifyContent={"flex-end"}>
            <Stack direction="row" spacing={0} alignItems="center">
              <Button
                rel="noreferrer"
                variant="lng_link"
                sx={{ minWidth: "20px", padding: "6px 6px", textDecoration: "none" }}
                onClick={() => changeLanguageTo("en")}
              >
                <Typography
                  variant="dg_footer"
                  size="sm"
                  fontWeight={mainContext.language === "en" ? "bold" : "light"}
                  sx={{
                    fontSize: "0.75rem",
                    textDecoration: mainContext.language === "en" ? "none" : "underline",
                  }}
                >
                  Eng
                </Typography>
              </Button>
              <Typography variant="dg_footer" size="sm" fontWeight="light">|</Typography>
              <Button
                rel="noreferrer"
                variant="lng_link"
                sx={{ minWidth: "20px", padding: "6px 6px", textDecoration: "none" }}
                onClick={() => changeLanguageTo("ja")}
              >
                <Typography
                  variant="dg_footer"
                  size="sm"
                  fontWeight={mainContext.language === "ja" ? "bold" : "light"}
                  sx={{
                    fontSize: "0.75rem",
                    textDecoration: mainContext.language === "ja" ? "none" : "underline",
                  }}
                >
                  日本語
                </Typography>
              </Button>
              <Typography variant="dg_footer" size="sm" fontWeight="light">|</Typography>
              <Button
                rel="noreferrer"
                variant="lng_link"
                sx={{ minWidth: "20px", padding: "6px 6px", textDecoration: "none" }}
                onClick={() => changeLanguageTo("id")}
              >
                <Typography
                  variant="dg_footer"
                  size="sm"
                  fontWeight={mainContext.language === "id" ? "bold" : "light"}
                  sx={{
                    fontSize: "0.75rem",
                    textDecoration: mainContext.language === "id" ? "none" : "underline",
                  }}
                >
                  ID
                </Typography>
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default Footer;
