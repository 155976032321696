import React, { Fragment } from "react";
//import { Suspense } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
//import { default as routes } from "./routes";
//import { renderRoutes as routes } from "./custAuthRoutes";
import { ProtectedRoutes } from "./components/AuthRoutes/ProtectedRoutes";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { isMobile, browserName } from "react-device-detect";
import { retrieve_location } from "./assets/utilities/retrieve_location";
// Google Analytics - GA4
import ReactGA from 'react-ga4';

import { theme } from "./assets/themes";
import { useNavigate } from "react-router-dom";

// Amplify related
//import { withAuthenticator } from "@aws-amplify/ui-react";

//import { Theme as AmplifyTheme, useTheme as useAmplifyTheme } from "@aws-amplify/ui-react";

// Material UI
import { Box, Grid } from "@mui/material";

// Components
// import AuthFormComponent from "./components/Authform";
// import AuthFormFields from "./components/AuthformFields";
// import AuthFormServices from "./components/AuthformServices";
import Navbar from "./components/Navbar";
import { useLocation, useSearchParams } from "react-router-dom";

// Cust Auth Provider
import { CustAuthProvider } from "./contexts/CustAuthContext";
import { MainContext } from "./contexts/MainContext";

import { useCookies } from 'react-cookie';

import "./App.css";

import Home from "./pages/Home";
import Signin from "./pages/Signin";
import Verify from "./pages/Verify";
import Welcome from "./pages/Welcome";
import Profile from "./pages/Profile";
import Quiz from "./pages/Quiz";
import Error from "./pages/Error";
import VerifyFailed from "./pages/VerifyFailedRetry";
import Waitlist from "./pages/Waitlist";
//import NotAuthorized from "./pages/NotAuthorized";

// Auth form
// const authform_component = AuthFormComponent;
// const authform_fields = AuthFormFields;
// const authform_services = AuthFormServices;


const TrackableRouterApp = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['ga_utm_campaign_id', 'ga_utm_campaign', 'ga_utm_campaign_source', 'ga_utm_campaign_medium', 'ga_utm_campaign_term', 'ga_utm_campaign_content']);
  const mainContext = React.useContext(MainContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    //console.log("[App] pathname: ", pathname, " searchParams: ", searchParams);
    if (searchParams.has("utm_id")) {
      mainContext.setGaCampaignId(searchParams.get("utm_id"));
      setCookie('ga_utm_campaign_id', searchParams.get("utm_id"), { path: '/' });
    }
    if (searchParams.has("utm_campaign")) {
      mainContext.setGaCampaign(searchParams.get("utm_campaign"));
      setCookie('ga_utm_campaign', searchParams.get("utm_campaign"), { path: '/' });
    }
    if (searchParams.has("utm_source")) {
      mainContext.setGaSource(searchParams.get("utm_source"));
      setCookie('ga_utm_campaign_source', searchParams.get("utm_source"), { path: '/' });
    }
    if (searchParams.has("utm_medium")) {
      mainContext.setGaMedium(searchParams.get("utm_medium"));
      setCookie('ga_utm_campaign_medium', searchParams.get("utm_medium"), { path: '/' });
    }
    if (searchParams.has("utm_term")) {
      mainContext.setGaTerm(searchParams.get("utm_term"));
      setCookie('ga_utm_campaign_term', searchParams.get("utm_term"), { path: '/' });
    }
    if (searchParams.has("utm_content")) {
      mainContext.setGaContent(searchParams.get("utm_content"));
      setCookie('ga_utm_campaign_content', searchParams.get("utm_content"), { path: '/' });
    }
    if (searchParams.has("applicant")) {
      mainContext.fillRegisterEmail(searchParams.get("applicant"));
    }
    if (searchParams.has("claim_nft")) {
      mainContext.updateRedirectClaimEventId(searchParams.get("claim_nft"));
      // Log Analytics
      mainContext.log_event_analytics("scan_qrcode", { event_id: searchParams.get("claim_nft") });
      ReactGA.event("scan_qrcode", { event_id: searchParams.get("claim_nft")});
      
    }
    mainContext.setPathname(pathname);
    mainContext.log_event_analytics("pageview", { path: pathname });
  }, [pathname]);

  React.useEffect(() => {
    // Route to page
    if (mainContext.homeRouteTo) {
      navigate(mainContext.homeRouteTo);
    }
  }, [mainContext.homeRouteTo]);

  return (
    <Routes>
      <Route path='/login' key="login" element={<Signin />} />
      <Route path='/verify' key="verify" element={<Verify />} />
      <Route path='/failed' key="failed" element={<VerifyFailed />} />
      <Route path='/error' key="error" element={<Error />} />
      <Route path='/waitlist' key="waitlist" element={<Waitlist />} />
      <Route element={<ProtectedRoutes />}>
        <Route path='/' key="home" element={<Home />} />
        <Route path='/profile' key="profile" element={<Profile />} />
        <Route path='/welcome' key="welcome" element={<Welcome />} />
        <Route path='/quiz' key="quiz" element={<Quiz />} />
      </Route>

      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};



const App = () => {
  const mainContext = React.useContext(MainContext);
  React.useEffect(() => {
    mainContext.setUserAgent(window.navigator.userAgent);
    mainContext.setIsMobile(isMobile);
    mainContext.setBrowserName(browserName);
    retrieve_location(mainContext);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',        
        width: "100%",
      }}
    >
    <ThemeProvider theme={theme}>
      <Fragment>
        <CssBaseline /> 
        <Grid container sx={{ maxWidth: "390px"}} justifyContent="center">
          <Grid item xs={12} container justifyContent="center">
                       
            <Navbar />
          </Grid>
            <Grid item xs={12} container justifyContent="center">
          <Box py={2} mx={1}>
            <BrowserRouter>
              <TrackableRouterApp />
            </BrowserRouter>
          </Box>
          </Grid>
        </Grid>      
      </Fragment>
    </ThemeProvider>
    </div>
  );
}



// const AuthApp = withAuthenticator(App, {
//   components: authform_component,
//   formFields: authform_fields,
//   services: authform_services,
//   hideSignUp: false,  
// });

const AuthApp = () => {
  return (
    <CustAuthProvider>
      <App />
    </CustAuthProvider>
  );
};


export default AuthApp;
