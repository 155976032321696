import React, { Fragment } from "react";
import { Grid, Box, Typography, Button, Stack, Divider, InputAdornment, FormControl, FormControlLabel, FormHelperText, Checkbox } from "@mui/material";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { MainContext } from "../../contexts/MainContext";
import { useAuth, CustAuthState } from "../../contexts/CustAuthContext";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { CssTextField } from "../../assets/themes/CssTextField";
import TrackableExtLink from "../../components/TrackableExtLink";
import { log_event_analytics } from "../../functions/log_event_analytics";
import CountdownButton from "../../components/CountdownButton";
import LandingSignUp from "../../components/LandingSignUp";
import logoGoogle from "../../assets/images/logo-google.png";
import imgCheckYourEmail from "../../assets/images/check-your-email.png";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Footer from "../../components/Footer";
import SignoutLink from "../../components/SignoutLink";

function Signin() {
  const mainContext = React.useContext(MainContext);
  const navigate = useNavigate();
  const { loggedIn, authState, signIn, resetMagicLinkFlow, nextMagicLinkAllowedAt, lastSubmittedEmail } = useAuth();
  const [showLoginForm, setShowLoginForm] = React.useState(true);
  const [showSentMagicLink, setShowSentMagicLink] = React.useState(false);
  const [formEmail, setFormEmail] = React.useState("");
  const [isFormEmailEditable, setIsFormEmailEditable] = React.useState(true);
  const [formTCChecked, setFormTCChecked] = React.useState(false);
  const [submittedEmail, setSubmittedEmail] = React.useState("");
  const [formLanguage, setFormLanguage] = React.useState(mainContext.language);
  const formError = formTCChecked ? false : true;

  const { t } = useTranslation();

  React.useEffect(() => {
    //console.log("authState: ", authState);
    if ((authState === CustAuthState.Unauthenticated) || (authState === CustAuthState.Unknown)) {
      setShowLoginForm(true);
      setShowSentMagicLink(false);
    } else if ((authState === CustAuthState.SentMagicLink) || (authState === CustAuthState.ResentMagicLink)) {
      setShowLoginForm(false);
      setShowSentMagicLink(true);
    } else {
      setShowLoginForm(false);
      setShowSentMagicLink(false);
    }
  }, [authState]);

  React.useEffect(() => {
    setFormLanguage(mainContext.language);
  }, [mainContext.language]);

  React.useEffect(() => {
    if (mainContext.isForceSameEmailRegister) {
      setFormEmail(mainContext.referralRegisterEmail);
      setIsFormEmailEditable(false);
    } else {
      setIsFormEmailEditable(true);
    }
  }, [mainContext.isForceSameEmailRegister]);

  return (
    <>
      <Box>
        {showLoginForm && (
          <Fragment>
            <FormControl
              required
              error={formError}
              component="fieldset"
              variant="standard"
            >
            <Grid container px={0} py={0} mx={0} my={0}>
              <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
                <LandingSignUp />
              </Grid>
              <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
                <Button                
                  variant="social"
                  fullWidth
                  onClick={() => {
                    log_event_analytics(mainContext, "attempt_google_signin", {});
                    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
                    mainContext.setUserSignInMethod("Google");
                  }}
                  disabled={!isFormEmailEditable}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <img src={logoGoogle} alt="Google" width={16} height={16} />
                    <Typography variant="dg_h3" sx={{ color: "var(--primary-red, #C00);" }}>{t("signin.google")}</Typography>
                  </Stack>
                </Button>
              </Grid>
              <Grid item xs={12} lg={12} mt={1} mx={2} container justifyContent={"center"}>
                <Typography variant="dg_body2" sx={{
                  color: "var(--primary-darkgrey, #2B2B2B)",
                  fontSize: "10px",
                  fontWeight: "400",
                  }}>
                  <Trans i18nKey="signin.google_acknowledgement"
                    components={{
                      br: <br />,
                      tc: <Typography
                        component={TrackableExtLink}
                        to="https://jp-faq.gryfyn.io/terms-and-conditions"
                        target="_blank"
                        rel="noreferrer"
                        variant="dg_body2"
                        sx={{
                          color: "var(--primary-red, #C00)",
                          fontSize: "10px",
                          textDecoration: "underline",
                        }}
                      />,                  
                      pp: <Typography
                        component={TrackableExtLink}
                        to="https://www.iubenda.com/privacy-policy/51877991/legal"
                        target="_blank"
                        rel="noreferrer"
                        variant="dg_body2"
                        sx={{
                          color: "var(--primary-red, #C00)",
                          fontSize: "10px",
                          textDecoration: "underline",
                        }}
                      />,
                    }} 
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12} mx={1} mt={1} container justifyContent={"center"}>
                <Divider sx={{ width: "100%" }}>
                  <Typography variant="dg_body1" sx={{ color: "var(--primary-grey, #676767)" }}>{t("signin.continue")}</Typography>
                </Divider>
              </Grid>
              <Grid item xs={12} lg={12} mt={2} mx={1} container justifyContent={"center"}>
                <CssTextField
                  height={"48px"} 
                  fullWidth sx={{ maxWidth: "350px" }}                  
                  label={t("signin.email_label")}
                  placeholder={t("signin.email_placeholder")}
                  value={formEmail}
                  onChange={(newValue) => { if (showLoginForm) setFormEmail(newValue.target.value) }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                  disabled={!isFormEmailEditable}
                />
              </Grid>
              <Grid item xs={12} lg={12} mt={2} mx={2} container justifyContent={"center"}>
              <FormControlLabel
                control={
                  <Checkbox checked={formTCChecked} onChange={() => setFormTCChecked(!formTCChecked)} name="tc"
                    sx={{
                      color: "var(--primary-red, #C00)",
                      '&.Mui-checked': {
                        color: "var(--primary-red, #C00)",
                      },
                    }} />
                }
                label={
                  <Typography variant="dg_body2" sx={{
                    color: "var(--primary-darkgrey, #2B2B2B)",
                    fontSize: "10px",
                    fontWeight: "400",
                    }}>
                    <Trans i18nKey="signin.tc"
                      components={{
                        br: <br />,
                        tc: <Typography
                          component={TrackableExtLink}
                          to="https://jp-faq.gryfyn.io/terms-and-conditions"
                          target="_blank"
                          rel="noreferrer"
                          variant="dg_body2"
                          sx={{
                            color: "var(--primary-red, #C00)",
                            fontSize: "10px",
                            textDecoration: "underline",
                          }}
                        />,                  
                        pp: <Typography
                          component={TrackableExtLink}
                          to="https://www.iubenda.com/privacy-policy/51877991/legal"
                          target="_blank"
                          rel="noreferrer"
                          variant="dg_body2"
                          sx={{
                            color: "var(--primary-red, #C00)",
                            fontSize: "10px",
                            textDecoration: "underline",
                          }}
                        />,
                      }} 
                    />
                  </Typography>
                }
              />
              </Grid>              
              <Grid item xs={12} lg={12} mt={2} mx={1} container justifyContent={"center"}>
                <Button variant="cta" fullWidth onClick={() => { 
                  setSubmittedEmail(formEmail.trim()); 
                  log_event_analytics(mainContext, "attempt_email_signin", { email: formEmail.trim(), language: formLanguage });
                  signIn({ email: formEmail.trim(), language: formLanguage });
                }}
                  disabled={!formTCChecked || formEmail === ""}
                  // sx={{
                  //   background: (formTCChecked && formEmail !== "") ? "var(--primary-red, #C00)" : "var(--primary-grey, #676767)",
                  // }}
                >
                  <Typography variant="dg_button">
                    {t("signin.signin_button")}
                  </Typography>
                </Button>
              </Grid>
              {formTCChecked ? "" : (
                <Grid item xs={12} lg={12} mx={1} container justifyContent={"center"}>                
                  <FormHelperText>{t("signin.form_error_tc")}</FormHelperText>
                  </Grid>
              )}
            </Grid>
            
            </FormControl>            
          </Fragment>
        )}

        {showSentMagicLink && (
          <Fragment>
            <Grid container px={0} py={0} mx={0} my={0}>
              <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
                <img src={imgCheckYourEmail} alt="Check your email" width={297} />
              </Grid>
              <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
                <Typography variant="dg_h1">
                  {t("sentml.check_your_email")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12} mt={1} container justifyContent={"center"}>
                <Stack direction={"column"} spacing={1} alignItems={"center"}>
                  <Typography variant="dg_body1">
                    <Trans i18nKey="sentml.sent_body_upper"
                      components={{
                        br: <br />,
                        b: <strong />,
                      }}
                      values={{email: submittedEmail}}
                    />
                  </Typography>
                  <Typography variant="dg_body1">
                    <Trans i18nKey="sentml.sent_body_bottom"
                      components={{
                        br: <br />,
                        highlight: <Typography sx={{ color: "var(--primary-red, #C00)", fontWeight: "700" }} />,
                      }}
                    />
                  </Typography>
                  <Typography variant="dg_body1">
                    {t("sentml.sent_reminder")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={12} mt={2} mx={1} container justifyContent={"center"}>
                {/* <Button variant="cta" onClick={() => {
                  log_event_analytics(mainContext, "attempt_resend_email", { email: submittedEmail, language: formLanguage });
                  signIn({ email: submittedEmail, language: formLanguage });
                }}>
                  <Typography variant="dg_button">
                    {t("sentml.resend_button")}
                  </Typography>
                </Button> */}
                <CountdownButton 
                  targetDate={nextMagicLinkAllowedAt} 
                  label={t("sentml.resend_button")} 
                  onClick={() => {
                    log_event_analytics(mainContext, "attempt_resend_email", { email: lastSubmittedEmail, language: formLanguage });
                    signIn({ email: lastSubmittedEmail, language: formLanguage });
                  }} />
              </Grid>
              <Grid item xs={12} lg={12} mt={2} mx={1} container justifyContent={"center"}>
                <Button variant="try_other" fullWidth
                  onClick={() => { 
                    log_event_analytics(mainContext, "try_another_email", {});
                    resetMagicLinkFlow(); 
                    navigate("/"); 
                  }}
                >
                  <Typography variant="dg_button" sx={{ color: "#2B2B2B" }}>
                    {t("sentml.try_another_button")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Box >
      { loggedIn && (
        <Fragment>
          <Grid container px={0} py={0} mx={0} my={0}>
            <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
              {/* <Button variant="contained" color="primary" onClick={() => Auth.signOut()}>
                {t("Sign Out")}
              </Button> */}
              <SignoutLink />
            </Grid>
          </Grid>
        </Fragment>
      )}
      <Box mx={0} mt={4}>
        <Footer />
      </Box>
    </>
  )
}

export default Signin;
