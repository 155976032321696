/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const proxywallet = /* GraphQL */ `
  mutation Proxywallet($input: AWSJSON) {
    proxywallet(input: $input)
  }
`;
export const proxyasset = /* GraphQL */ `
  mutation Proxyasset($input: AWSJSON) {
    proxyasset(input: $input)
  }
`;
export const proxyclaim = /* GraphQL */ `
  mutation Proxyclaim($input: AWSJSON) {
    proxyclaim(input: $input)
  }
`;
export const updatewaitlist = /* GraphQL */ `
  mutation Updatewaitlist($input: AWSJSON) {
    updatewaitlist(input: $input)
  }
`;
export const createDGF1JP2023UserRedirect = /* GraphQL */ `
  mutation CreateDGF1JP2023UserRedirect(
    $input: CreateDGF1JP2023UserRedirectInput!
    $condition: ModelDGF1JP2023UserRedirectConditionInput
  ) {
    createDGF1JP2023UserRedirect(input: $input, condition: $condition) {
      pk
      userEmail
      redirectUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDGF1JP2023UserRedirect = /* GraphQL */ `
  mutation UpdateDGF1JP2023UserRedirect(
    $input: UpdateDGF1JP2023UserRedirectInput!
    $condition: ModelDGF1JP2023UserRedirectConditionInput
  ) {
    updateDGF1JP2023UserRedirect(input: $input, condition: $condition) {
      pk
      userEmail
      redirectUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDGF1JP2023UserRedirect = /* GraphQL */ `
  mutation DeleteDGF1JP2023UserRedirect(
    $input: DeleteDGF1JP2023UserRedirectInput!
    $condition: ModelDGF1JP2023UserRedirectConditionInput
  ) {
    deleteDGF1JP2023UserRedirect(input: $input, condition: $condition) {
      pk
      userEmail
      redirectUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDGF1JP2023FormResponse = /* GraphQL */ `
  mutation CreateDGF1JP2023FormResponse(
    $input: CreateDGF1JP2023FormResponseInput!
    $condition: ModelDGF1JP2023FormResponseConditionInput
  ) {
    createDGF1JP2023FormResponse(input: $input, condition: $condition) {
      id
      owner
      email
      formId
      walletAddress
      responseId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDGF1JP2023FormResponse = /* GraphQL */ `
  mutation UpdateDGF1JP2023FormResponse(
    $input: UpdateDGF1JP2023FormResponseInput!
    $condition: ModelDGF1JP2023FormResponseConditionInput
  ) {
    updateDGF1JP2023FormResponse(input: $input, condition: $condition) {
      id
      owner
      email
      formId
      walletAddress
      responseId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDGF1JP2023FormResponse = /* GraphQL */ `
  mutation DeleteDGF1JP2023FormResponse(
    $input: DeleteDGF1JP2023FormResponseInput!
    $condition: ModelDGF1JP2023FormResponseConditionInput
  ) {
    deleteDGF1JP2023FormResponse(input: $input, condition: $condition) {
      id
      owner
      email
      formId
      walletAddress
      responseId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDGF1JP2023ChampionshipQuest = /* GraphQL */ `
  mutation CreateDGF1JP2023ChampionshipQuest(
    $input: CreateDGF1JP2023ChampionshipQuestInput!
    $condition: ModelDGF1JP2023ChampionshipQuestConditionInput
  ) {
    createDGF1JP2023ChampionshipQuest(input: $input, condition: $condition) {
      id
      owner
      email
      gameEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDGF1JP2023ChampionshipQuest = /* GraphQL */ `
  mutation UpdateDGF1JP2023ChampionshipQuest(
    $input: UpdateDGF1JP2023ChampionshipQuestInput!
    $condition: ModelDGF1JP2023ChampionshipQuestConditionInput
  ) {
    updateDGF1JP2023ChampionshipQuest(input: $input, condition: $condition) {
      id
      owner
      email
      gameEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDGF1JP2023ChampionshipQuest = /* GraphQL */ `
  mutation DeleteDGF1JP2023ChampionshipQuest(
    $input: DeleteDGF1JP2023ChampionshipQuestInput!
    $condition: ModelDGF1JP2023ChampionshipQuestConditionInput
  ) {
    deleteDGF1JP2023ChampionshipQuest(input: $input, condition: $condition) {
      id
      owner
      email
      gameEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDGF1JP2023Waitlist = /* GraphQL */ `
  mutation CreateDGF1JP2023Waitlist(
    $input: CreateDGF1JP2023WaitlistInput!
    $condition: ModelDGF1JP2023WaitlistConditionInput
  ) {
    createDGF1JP2023Waitlist(input: $input, condition: $condition) {
      email
      requested_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDGF1JP2023Waitlist = /* GraphQL */ `
  mutation UpdateDGF1JP2023Waitlist(
    $input: UpdateDGF1JP2023WaitlistInput!
    $condition: ModelDGF1JP2023WaitlistConditionInput
  ) {
    updateDGF1JP2023Waitlist(input: $input, condition: $condition) {
      email
      requested_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDGF1JP2023Waitlist = /* GraphQL */ `
  mutation DeleteDGF1JP2023Waitlist(
    $input: DeleteDGF1JP2023WaitlistInput!
    $condition: ModelDGF1JP2023WaitlistConditionInput
  ) {
    deleteDGF1JP2023Waitlist(input: $input, condition: $condition) {
      email
      requested_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
