import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
//import { Auth } from "aws-amplify";
import { MainContext } from "../../contexts/MainContext";
import { useAuth } from "../../contexts/CustAuthContext";
import { Backdrop, CircularProgress } from "@mui/material";

function Verify() {
  const mainContext = React.useContext(MainContext);
  const { verify } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [openLoading, setOpenLoading] = React.useState(true);
  const [verifyEmail, setVerifyEmail] = React.useState("");
  const [verifyAuthCode, setVerifyAuthCode] = React.useState("");

  // const isAuthenticated = React.useCallback(async () => {
  //   try {
  //     console.log("isAuthenticated() -> wait for result");
  //     await Auth.currentSession();
  //     return true;
  //   } catch (error) {
  //     return false;
  //   }
  // }, []);
  
  React.useEffect(() => {
    setOpenLoading(true);
    if (searchParams.has("email")) {
      setVerifyEmail(searchParams.get("email"));
    }
    if (searchParams.has("code")) {
      setVerifyAuthCode(searchParams.get("code"));
    }
    if (searchParams.has("email") && searchParams.has("code")) {
      // init Amplify Auth with cust flow and using auth code
      try {
        const emailParam = searchParams.get("email").toLowerCase();
        const authCodeParam = searchParams.get("code");
        //console.log("init Amplify Auth with cust flow and using auth code");
        //setOpenLoading(false);
        verify(emailParam, authCodeParam).then((verifyResult) => {  
          //console.log("verifyResult: ", verifyResult);
          if (verifyResult) {
            mainContext.setUserSignInMethod("Email MagicLink");
            setOpenLoading(false);
            navigate("/");
          } else {
            // Retry with exponential backoff for 3 times
            setTimeout(() => {
              verify(emailParam, authCodeParam).then((verifyResult2nd) => {
                if (verifyResult2nd) {
                  mainContext.setUserSignInMethod("Email MagicLink");
                  setOpenLoading(false);
                  navigate("/");
                } else {
                  setTimeout(() => {
                    verify(emailParam, authCodeParam).then((verifyResult3rd) => {
                      if (verifyResult3rd) {
                        mainContext.setUserSignInMethod("Email MagicLink");
                        setOpenLoading(false);
                        navigate("/");
                      } else {
                        // After 3 times, navigate to failed page
                        setOpenLoading(false);
                        navigate("/failed");
                      }
                    });
                  }, 2000);
                }
              });
            }, 2000);
          }
        }).catch((err) => {
          console.log("Error in verify: ", err);
        });
      } catch (gerr) {
        console.log("General error in verify: ", gerr);
      }
    }
  }, [searchParams, setSearchParams]);

  React.useEffect(() => {

  }, [verifyEmail, verifyAuthCode]);

  const progressRedStyle = {
    "&.MuiCircularProgress-colorPrimary": {
      color: "rgba(255, 0, 0, 0.84) !important",
    }
  };


  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <CircularProgress sx={progressRedStyle} />
      </Backdrop>
    </>
  );
}

export default Verify;
