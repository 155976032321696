import { createIcon } from "./createIcon";

// eslint-disable-next-line react/prop-types
const Avatar = ({ email, walletAddress }) => {
  const seed = email || "default";
  const address = walletAddress || "0x12345678";
  const pr = parseInt(address.slice(2, 4), 16);
  const pg = parseInt(address.slice(4, 6), 16);
  const pb = parseInt(address.slice(6, 8), 16);
  const pa = parseInt(address.slice(-2), 16);
  //console.log("Avatar: ", seed, address, pr, pg, pb, pa);

  const color_r = 255 - (pr / 255) * 50;
  const color_g = 0 + (pg / 255) * 50;
  const color_b = 0 + (pb / 255) * 50;
  const color_a = 0.8 + (pa / 255) * 0.2;

  const spot_r = 255 - (pg / 255) * 40 + (pa / 255) * 10;
  const spot_g = (pb / 255) * 120 - (pa / 255) * 20;
  const spot_b = (pr / 255) * 120 - (pa / 255) * 20;
  const spot_a = 0.6 + (pa / 255) * 0.2 + (pr / 255) * 0.2;

  const bg_r = 255 - (pb / 255) * 120;
  const bg_g = 0 + (pr / 255) * 20;
  const bg_b = 0 + (pg / 255) * 20;
  const bg_a = 0.6 + (pa / 255) * 0.2;

  const canvas = createIcon({
    seed: seed,
    color: `rgba(${color_r}, ${color_g}, ${color_b}, ${color_a})`,
    spotcolor: `rgba(${spot_r}, ${spot_g}, ${spot_b}, ${spot_a})`,
    bgcolor: `rgba(${bg_r}, ${bg_g}, ${bg_b}, ${bg_a})`,
    size: 88, // width/height of the icon in blocks, default: 32
  });
  // console.log("color: ", color_r, color_g, color_b, color_a);
  // console.log("spotcolor: ", spot_r, spot_g, spot_b, spot_a);
  // console.log("bgcolor: ", bg_r, bg_g, bg_b, bg_a);

  if ((email) && (walletAddress)) {
    return (
      <>      
        <img src={canvas.toDataURL()} style={{borderRadius: "50%"}} alt="Avatar" />      
      </>
    );
  } else {
    return (
      <>
        <div style={{width: "88px", height: "88px" }} />      
      </>
    );
  }
};

export default Avatar;
