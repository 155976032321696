/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AuthApp from './App';
import { MainProvider } from "./contexts/MainContext";
import reportWebVitals from './reportWebVitals';
//import PublicVerify from './PublicVerify';

// Amplify related
import { Amplify, Auth, Analytics, AWSKinesisProvider } from "aws-amplify";
import awsExports from "./aws-exports";

import "./i18n";
import "./i18n-amplify";

// Google Analytics - GA4
import ReactGA from 'react-ga4';

import { CookiesProvider } from 'react-cookie';

// Enable GA4 tracking
// PageView will be automated by Enhanced measurement (which is described by Google: Automatically measure interactions and content on your sites in addition to standard page view measurement.)
ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID || "");

// Overriding oAuth domain and web client for Google
awsExports.aws_user_pools_web_client_id = process.env.REACT_APP_AWS_WEB_CLIENT_ID || awsExports.aws_user_pools_web_client_id;
awsExports.oauth.domain = process.env.REACT_APP_AUTH_CUSTOM_IDP_URL || "auth.passwordless.dttd.wtf";
awsExports.oauth.redirectSignIn = process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN || awsExports.oauth.redirectSignIn;
awsExports.oauth.redirectSignOut = process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT || awsExports.oauth.redirectSignOut;




Amplify.configure(awsExports);

// Override auth flow to use custom auth
Auth.configure({
  authenticationFlowType: 'CUSTOM_AUTH',
});

Analytics.addPluggable(new AWSKinesisProvider());

Analytics.configure({
  AWSKinesis: {
    // OPTIONAL -  Amazon Kinesis service region
    region: awsExports.aws_project_region,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>    
  
  <CookiesProvider defaultSetOptions={{ path: '/' }}>
    <MainProvider>
      {/* <PublicVerify /> */}
      <AuthApp />
    </MainProvider>
  </CookiesProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
