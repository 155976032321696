import {  API, Auth } from "aws-amplify";

export const callSignIn = (email, language) => {
  try {
    const apiName = "dgf1jp2023viprestapi";
    const path = '/signin';
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        email: email,
        language: language,
      }
    };

    API.post(apiName, path, myInit)
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        //console.log("[callSignIn] API response:", response);
        
        Auth.signIn(email).then((user) => {
          return user;
        }).catch((signInErr) => {
          console.log("Error in sign-in: ", signInErr);
        });
      })
      .catch((error) => {
        console.log("[callSign] Error in Posting API: ", error.response);        
      });
  } catch (error) {
    console.log("[callSignIn] Error in API call: ", error);
    throw new Error(error);
  }
};
